import React from 'react'
import { Route, Switch } from 'react-router-dom';
import {Row, Col} from 'react-bootstrap'; 

import Connexions from './Connexions';
import Reactions from './Reactions'; 

// CSS 
import './_stats.scss'
import { Link } from 'react-router-dom';   
import ReportingCommunities from './ReportingCommunities';


const Statistics = (props)=>{
    return(
        <>
            <div className="_stats" > 
                <Switch>
                    <Route exact path="/administration/stats" component={()=>{
                        return(
                            <div className="icons-menu">
                                <Row> 
                                    <Col md={4} sm={6}  xs={12}>
                                        <Link to="/administration/stats/connexions" className="menu-item">
                                            <h3>Connexions</h3>
                                            <p>ID, Nom, prénom, Email et dernière connexion.</p>
                                        </Link>
                                    </Col> 
                                    <Col md={4} sm={6}  xs={12}>
                                        <Link to="/administration/stats/reactions" className="menu-item">
                                            <h3>Stats Utilisateurs</h3>
                                            <p>Nombre de posts, commentaires, likes, vues...</p>
                                        </Link>
                                    </Col>  
                                    <Col md={4} sm={6}  xs={12}>
                                        <Link to="/administration/stats/communities" className="menu-item">
                                            <h3>Reporting Communautés</h3>
                                            <p>Statistique par communauté post, like, commentaires.</p>
                                        </Link>
                                    </Col> 
                                </Row>
                            </div>
                        )
                    }} />
                    <Route exact path="/administration/stats/connexions" component={Connexions} />  
                    <Route exact path="/administration/stats/reactions" component={Reactions} />   
                    <Route exact path="/administration/stats/communities" component={ReportingCommunities} />  
                </Switch> 
            </div>
        </>
    )
}
export default Statistics