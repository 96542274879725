import React from 'react'
import PdfViewer from '../widgets/PdfViewer'

export default function PdfViewerPage() {
    return (
        <div id="PdfViewer">
            <PdfViewer></PdfViewer>
        </div>
    )
}
