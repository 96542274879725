import React, { Component } from 'react'
//import configs from '../../inc/helpers.js'
import {Link, withRouter} from 'react-router-dom'
import Axios from 'axios';
import Global from '../../inc/Global.js';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import BeatLoader from "react-spinners/BeatLoader";
import Pusher from 'pusher-js' 

class Community extends Component
 {
    constructor(props){
        super(props);

        this.state = {
            communities : [],
            currentCommunityId: this.props.match.params.id || null
        };
        this.initPusher();
    }

    initPusher(){
        let pusherClient = new Pusher(Global.PUSHER.app_key, { cluster: 'eu' }); 
        const channel = pusherClient.subscribe(Global.PUSHER.channel);

        channel.bind(Global.PUSHER.event,(data) => {
            this.getNotificationsCounter();
        }); 
    }

    getNotificationsCounter(){
        Axios
        .get(Global.API_URL+"/api/notif/count", {})
        .then((res) => {
            if( res.data.state && res.data.nombregroupenewpost > 0 ){
                this.componentDidMount()
            }
        })
    }
    
    async componentDidUpdate(prevProps) {

        await new Promise(resolve => setTimeout(resolve, 1000));
        
        
        if (prevProps.location.pathname === "/communautes/:id/modifier" || (prevProps.location.pathname !== this.props.location.pathname)) {
            console.log("ROOT CHANGED"); 
            const data = {
                nbCommunity : 99999999999,
                startPoint : 0,
                idUser : this.props.auth.user.user_id,
                type : '',
                hasNotif : true
            }
            Axios
            .post(Global.API_URL+"/api/getcommunities", data)
            .then((res) => {

                // console.log(res.data)
                var communities = [];
                res.data.forEach(function(item, key) {
                    communities.push({
                        ID : item.id_group,
                        Color : item.couleur,
                        Logo : item.logo,
                        Cover : item.photocouverture,
                        Subject : item.sujet,
                        Title : item.titre,
                        Type : item.typegroupe,
                        NbPostNonLu: item.nb_postnonlu
                    });
                });

                this.setState({
                    communities : communities,
                })            
            })
            .catch((err) => {
                // console.log(err.message);
            });
        }
        if( this.state.currentCommunityId != this.props.match.params.id){
            this.setState({
                currentCommunityId: this.props.match.params.id || null
            })
        }
	}
    
    componentDidMount(){
        const data = {
            nbCommunity : 99999999999,
            startPoint : 0,
            idUser : this.props.auth.user.user_id,
            type : '',
            hasNotif : true
        }
        Axios
        .post(Global.API_URL+"/api/getcommunities", data)
        .then((res) => {

            // console.log(res.data);
            var communities = [];
            res.data.forEach(function(item, key) {
                communities.push({
                    ID : item.id_group,
                    Color : item.couleur,
                    Logo : item.logo,
                    Cover : item.photocouverture,
                    Subject : item.sujet,
                    Title : item.titre,
                    Type : item.typegroupe,
                    NbPostNonLu: item.nb_postnonlu
                });
            });
            
            // console.log(communities)
            this.setState({
                communities : communities,
            })            
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }

    render() {

        return (
            <div>
                <div className="widget widget-communaute">
                    <h2 className="title">Mes Communautés</h2>
                    <div className="widget-content communaute">
                        <div className="list-communaute">
                            {
                                this.state.communities.length == 0 &&
                                <center style={{padding: 20, fontSize: 20}}>
                                    <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                </center>
                            }
                            <ul>
                                {this.state.communities.map((item, index) =>{
                                    return (
                                        <li className={`item ${this.state.currentCommunityId == item.ID ? 'active' : ''}`} key={"widget-cmy-"+index}>
                                            <Link to={"/communaute/" + item.ID}>
                                                <div className="figure">
                                                    <div>
                                                        {!item.Logo &&
                                                            item.Title.charAt(0)
                                                        }
                                                        {item.Logo &&
                                                            <img src={Global.API_URL +"/" + item.Logo} alt="" />
                                                        }
                                                    </div> 
                                                </div>
                                                <div className="content-item">
                                                    <h3 className="entry-title">
                                                        {item.Title}
                                                    </h3>
                                                    {
                                                        item.NbPostNonLu > 0 &&
                                                        <span className="badge">{item.NbPostNonLu}</span>
                                                    }
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })} 
                            </ul>
                        </div>
                    </div>
                    {
                        this.state.communities.length > 0 &&
                            <div className="widget-footer text-center">
                            <Link to="/communautes" className="">
                                toutes les communautés
                                <i className="fa fa-chevron-right"></i>
                            </Link>
                        </div>
                    } 
                </div>
            </div>
        )
    }
}
Community.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(Community));