import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import Posts from '../publications/Posts';
import SHA1 from 'sha1'
import Axios from 'axios';
import Global from '../../inc/Global';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import Documents from './documents/Documents';
import configs from '../../inc/helpers';
import BeatLoader from "react-spinners/BeatLoader";
import Polls from './Polls';
import CallenderCom from './CallenderCom';

class Community extends Component {
    constructor(props){
        super(props);

        this.state = {
            communityid : this.props.match.params.id,
            communityFolderId : this.props.match.params.folderId ? this.props.match.params.folderId : false,
            communityData : null,
            loaded : false,
            delete : false,
            leaveCommunity : false
            
        }

        this.DeleteBtn = React.createRef();
        this.LeaveBtn = React.createRef();
    }
    componentDidMount(){
        const data = {
            id : this.state.communityid
        };
        
        Axios
        .post(Global.API_URL+"/api/getgroupspecifique/mur?c="+SHA1(new Date()), data)
        .then((res) => {
            if( res.data[0].group){
                this.setState({
                    communityData : res.data[0],
                    loaded : true
                })
            }
            else{
                this.props.history.push('/404')
            }
        
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }
    componentDidUpdate(prevProps) {
        
        if (this.state.communityid !== this.props.match.params.id) {
            this.setState({ 
                communityid: this.props.match.params.id,
                communityFolderId : this.props.match.params.folderId ? this.props.match.params.folderId : false,
            });

            const data = {
                id : this.props.match.params.id
            };
            this.setState({
                communityData : null,
                loaded : false
            })
            
            Axios
            .post(Global.API_URL+"/api/getgroupspecifique/mur?c="+SHA1(new Date()), data)
            .then((res) => {
                this.setState({
                    communityData : res.data[0],
                    loaded : true
                })
            
            })
            .catch((err) => {
                // console.log(err.message);
            });
        }

        if(this.props.match.params.folderId){
            if(this.state.communityFolderId !== this.props.match.params.folderId){
                this.setState({
                    communityFolderId : this.props.match.params.folderId ? this.props.match.params.folderId : false
                })
            }
        }
    }
    handleLeave (ev){
        ev.preventDefault();

        this.setState({
            leaveCommunity : true
        })
    }
    handleDelete (ev){
        ev.preventDefault();

        this.setState({
            delete : true
        })
    }
    cancel (ev){
        ev.preventDefault();

        this.setState({
            delete : false,
            leaveCommunity : false
        })
    }
    confirmLeaving (e){
        e.preventDefault();
        this.LeaveBtn.current.classList.add('on');
        
        const data = {
            id: this.state.communityid,
            email: this.props.auth.user.username,
        }
        
        Axios
        .post(Global.API_URL+"/quittergroup", data)
        .then((res) => {
            this.LeaveBtn.current.classList.remove('on');
            this.props.history.push('/communautes');
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            this.LeaveBtn.current.classList.remove('on');
            this.setState({
                delete : false
            });
        });
    }
    confirmDeletion (e){
        e.preventDefault();
        this.DeleteBtn.current.classList.add('on');
        
        
        Axios
        .delete(Global.API_URL+"/deletegroup/"+this.state.communityid)
        .then((res) => {
            this.DeleteBtn.current.classList.remove('on');
            this.props.history.push('/');            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            this.DeleteBtn.current.classList.remove('on');
            this.setState({
                delete : false
            });
        });
    }
    
    render() {
        const { communityData } = this.state

        if(communityData === null){
            return (
                <BeatLoader color={"#aaa"} loading={true} size="10"/>
            )
        }

        return (
            <div style={{width: '100%'}} className={`content ${this.props.match.params.postId ? 'single-post' : ''}`}>
                <section className="">

                    <Switch>
                        <Route exact path={['/communaute/:id']}>
                            <div className="wrap community" id="community">
                                <div className="header-community">
                                    {
                                        communityData.group.photocouverture && 
                                        <div className="banner">
                                            <img src={Global.API_URL + communityData.group.photocouverture} alt="" />
                                        </div>
                                    } 
                                    
                                    <div className="max-width">
                                        <div className="wrap">
                                            
                                            {
                                                this.state.communityData.userconnecterole !== "ROLE_ADMIN" &&
                                                <div className="rm-list">
                                                    <input type="checkbox" id={"_cmtpg-" + this.state.communityid} />
                                                    <label className="toggle" htmlFor={"_cmtpg-" + this.state.communityid}>...</label>
                                                    <div className="rm-list-item">
                                                        <ul>
                                                            <li><Link to="" onClick={this.handleLeave.bind(this)}>Quitter la communauté</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }

                                            <div className="d-flex logo_name">
                                                {
                                                    communityData.group.logo && 
                                                    <div className="logo" style={{backgroundImage : 'url(' + Global.API_URL  + communityData.group.logo + ')'}}></div>
                                                }
                                                {
                                                    !communityData.group.logo && 
                                                    <div className="logo" style={{backgroundColor : communityData.group.couleur}}>
                                                        {communityData.group.titre.charAt(0)+communityData.group.titre.charAt(1)}
                                                    </div>
                                                }
                                                <h2>
                                                    {communityData.group.titre}  
                                                    {communityData.group.typegroupe === "defaut" && 
                                                        <span>Communauté entreprise</span>
                                                    }
                                                    {communityData.group.typegroupe === "public" && 
                                                        <span>Communauté publique</span>
                                                    }
                                                    
                                                    {communityData.group.typegroupe === "private" && 
                                                        <span>Communauté privée</span>
                                                    }
                                                    
                                                    {communityData.group.typegroupe === "secret" && 
                                                        <span>Communauté secrète</span>
                                                    } 
                                                </h2>
                                            </div>  
                                            
                                            <div className="d-flex jc-fs community-settings">
                                                {/* <div><span className="fa fa-bar-chart"></span></div>
                                                <div><span className="fa fa-calendar"></span></div> */}
                                                
                                                <div>
                                                    <NavLink activeClassName="active" to={"/communaute/" + this.state.communityid + "/mediatheque"}>Documents</NavLink>
                                                </div>
                                                <div>
                                                    <NavLink activeClassName="active" to={"/communaute/" + this.state.communityid + "/calendrier"}> Calendrier </NavLink>
                                                </div>
                                                <div>
                                                        <NavLink activeClassName="active" to={"/communaute/" + this.state.communityid + "/sondages"}>Sondages</NavLink>
                                                </div>
                                              
                                                {/* {
                                                    ( configs.isAdmin() || this.state.communityData.userconnecterole === "ROLE_ADMIN") &&
                                                    <div>
                                                        <NavLink activeClassName="active" to={"/communaute/" + this.state.communityid + "/sondages"}>Sondages</NavLink>
                                                    </div>
                                                }
                                                 */}
                                                {( configs.isAdmin() || this.state.communityData.userconnecterole === "ROLE_ADMIN") && 
                                                    <div>
                                                        <NavLink 
                                                            activeClassName="active"
                                                            to={{
                                                                pathname : `/communaute/${communityData.group.id}/parametres`,
                                                                state : {
                                                                    id : communityData.group.id,
                                                                    logo : communityData.group.logo,
                                                                    cover : communityData.group.photocouverture,
                                                                    title : communityData.group.titre,
                                                                    type : communityData.group.typegroupe,
                                                                    subject : communityData.group.sujet,
                                                                    nbuser : communityData.nbuser,
                                                                    invitation : 0,
                                                                    email : this.props.auth.user.username,
                                                                    color : communityData.group.couleur
                                                                }
                                                            }}
                                                            >Paramètres
                                                        </NavLink>
                                                    </div>
                                                } 
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Route>
                    </Switch> 
                </section>  

                <Switch>
                    <Route exact path="/communaute/:id" render={() => <Posts auth={this.props.auth} role={communityData.userconnecterole} parent="page_community" api_url="/api/publication" community={this.state.communityid}  formAddPost={true} />} />
                    <Route exact path="/communaute/:id/post/:postId" render={() => <Posts auth={this.props.auth} role={communityData.userconnecterole} parent="page_community" community={this.state.communityid} />} />
                    <Route exact path="/communaute/:id/mediatheque/:folderId" >
                        <Documents communityData={communityData} communityId={this.state.communityid} folder={this.state.communityFolderId} />
                    </Route>
                    <Route exact path="/communaute/:id/mediatheque/:folderId/:default" >
                        <Documents communityData={communityData} communityId={this.state.communityid} folder={this.state.communityFolderId} default={true} />
                    </Route>
                    <Route exact path="/communaute/:id/mediatheque"  >
                        <Documents communityData={communityData} communityId={this.state.communityid} folder={false} />
                    </Route>
                    <Route exact path="/communaute/:id/sondages" >
                        <Polls communityid={this.state.communityid} role={communityData.userconnecterole} />
                    </Route>
                    <Route exact path="/communaute/:id/calendrier" >
                        <CallenderCom communityid={this.state.communityid} role={communityData.userconnecterole} id="aaaa" ></CallenderCom>
                    </Route>
                </Switch>
                
                
                {this.state.delete && 
                    <div className="_modal confirmation DeleteCommunity" id="DeleteCommunity">
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir supprimer cette communauté&nbsp;?</h2>
                                </div>
                                <div className="_modal-body">
                                <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                    <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                    <button onClick={this.confirmDeletion.bind(this)} ref={this.DeleteBtn} className="button lg">
                                        <span className="fa fa-spin fa-spinner"></span> Supprimer
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.leaveCommunity && 
                    <div className="_modal DeleteCommunity confirmation" id="DeleteCommunity">
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir quitter cette communauté&nbsp;?</h2>
                                </div>
                                <div className="_modal-body">
                                <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                    <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                    <button onClick={this.confirmLeaving.bind(this)} ref={this.LeaveBtn} className="button lg">
                                        <span className="fa fa-spin fa-spinner"></span> Quitter
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

Community.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {} )(Community);
