import Axios from 'axios';
import Pusher from 'pusher-js';
import React, { Component } from 'react'
import {Link} from 'react-router-dom' 
import Global from '../../inc/Global';
import configs from '../../inc/helpers';
import BeatLoader from "react-spinners/BeatLoader";

class Notifications extends Component {
    constructor(){
        super()
        this.state = {
            loading: false,
            showNotifications: false,
            notificationsCounter: 0,
            notifications: [] 
        }   
        this.getNotificationsCounter();
        this.initPusher();
    }

    initPusher(){
        let pusherClient = new Pusher(Global.PUSHER.app_key, { cluster: 'eu' }); 
        const channel = pusherClient.subscribe(Global.PUSHER.channel);

        channel.bind(Global.PUSHER.event,(data) => {
            this.getNotificationsCounter();
        });

    }

    getNotifications(){
        this.setState({
            showNotifications: !this.state.showNotifications, 
            showActionsButtons: false, 
            loading: true
        }, ()=>{
            if( this.state.showNotifications ){
                // document.getElementById('notifications').scrollTop()
                Axios
                .post(Global.API_URL+"/api/notif/load", {
                    nb: 999999,
                    start: 0,
                })
                .then((res) => {
                    if( res.data.status ){
                        this.setState({
                            loading: false,
                            notifications: res.data.notification,
                            notificationsCounter: 0
                        })
                    }
                })
            }
        }) 
    }

    getNotificationsCounter(){
        Axios
        .get(Global.API_URL+"/api/notif/count", {})
        .then((res) => {
            if( res.data.state ){
                this.setState({
                    notificationsCounter: res.data.nombrenotif
                })
            }
        })
    }

    setNotificationAsReaded(index, notification){
        let notifications = this.state.notifications;
        notifications[index].lue = 1;
        this.setState({
            showNotifications: notification.idpost ? false : true,
            notifications: notifications
        })
        Axios.post(Global.API_URL+"/api/notif/"+notification.id, {}).then((res) => {

        })
    }

    setAllNotificationsAsReaded(){
        let notifications = this.state.notifications;
        notifications.map(n=> n.lue = 1)
        this.setState({
            notifications: notifications,
            showActionsButtons: false,
            notificationsCounter: 0
        })
        Axios.post(Global.API_URL+"/api/marquelu", {}).then((res) => {})
    }

    deleteAllNotifications(){ 
        this.setState({
            notifications: [],
            showActionsButtons: false,
            showNotifications: false,
            notificationsCounter: 0
        })
        Axios.delete(Global.API_URL+"/api/deletenotif", {}).then((res) => {})
    }

    render() {
        return (
            <div className="notifications" id="notifications">
                {
                    this.state.showNotifications &&
                    <div className="notifications-overley" onClick={()=>{this.setState({showNotifications: false, showActionsButtons: false})}}></div>
                }  
                
                <button className={`toggle-notifications ${this.state.showNotifications ? 'active' : ''}`} onClick={()=>{this.getNotifications()}}>
                    <i className="fa fa-bell" aria-hidden="true"></i> 
                    {
                        this.state.notificationsCounter > 0 &&
                        <span className="badge">{this.state.notificationsCounter}</span>
                    } 
                </button>
                <div className={`notifications-content ${this.state.showNotifications ? 'active' : ''}`}>
                    {
                        this.state.notifications.length > 0 &&
                        <h2>
                            Notifications 
                            <button onClick={()=>{this.setState({showActionsButtons: !this.state.showActionsButtons})}}>•••</button> 
                            
                            {
                                this.state.showActionsButtons &&
                                <ul className="actions">
                                    <li onClick={()=>{this.setAllNotificationsAsReaded()}}><i className="fa fa-check" aria-hidden="true"></i> Marquer comme lues</li>
                                    <li onClick={()=>{this.deleteAllNotifications()}}><i className="fa fa-trash-o" aria-hidden="true"></i> Supprimer toutes les notifications</li>
                                </ul>
                            }
                        </h2>
                    }
                    
                    {
                        this.state.notifications.length > 0 &&
                        <ul className="notifications-list">
                            {this.state.notifications.map((notification, key)=>{
                                return(
                                    <li className={notification.lue === 0 ? 'active' : ''}>
                                        <Link to={notification.idpost ? `/communaute/${notification.idgroup}/post/${notification.idpost}` : '#'} onClick={()=>{this.setNotificationAsReaded(key, notification)}}>
                                            <figure 
                                                style={notification.imageauteur ? {backgroundImage: 'url('+ Global.API_URL + notification.imageauteur+')'} : {}}
                                            >
                                                {!notification.imageauteur ? notification.nomauteur.charAt(0) : ''}
                                            </figure>
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: notification.Description}}></p>
                                                <abbr>{configs.timeSince(notification.createdAt)}</abbr>
                                            </div>
                                        </Link>
                                    </li> 
                                )
                            })}
                        </ul>
                    }
                    {
                        this.state.notifications.length === 0 &&
                        <center className="no-result">
                            {
                                !this.state.loading &&
                                <span>Aucune notification</span>
                            }
                            {
                                this.state.loading &&
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            } 
                        </center>
                    } 
                        
                </div>
            </div>
        )
    }
}

export default Notifications;