import React from 'react'
import { Route, Switch } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';


/// components 
import Settings from './Settings'
import Headband from './Headband';
import Banners from './Banners';
import BreakingNews from './BreakingNews';
import Links from './Links';
import { Link } from 'react-router-dom';  
import Statistics from './stats/';
 
// CSS
import './_administration.scss'


const Administration = (props)=>{
    return(
        <>
            <div className="_administration" > 
                <div className="header-admin">
                    <h3>
                        <center>
                            <a href onClick={()=>{window.history.back()}}>
                                <span className="fa fa-long-arrow-left"></span>
                            </a>
                            Administration de la plate-forme
                        </center>
                    </h3>
                </div>
                <Row> 
                    <Col>
                        <div className="box"> 

                            <Switch>
                                <Route exact path="/administration" component={()=>{
                                    return(
                                        <div className="icons-menu">
                                            <Row> 
                                                <Col md={4} sm={6}  xs={12}>
                                                    <Link to="/administration/settings" className="menu-item">
                                                        <h3>Nom et icones / Couleur</h3>
                                                        <p>Modifiez le nom de la plate-forme, les logos et les couleurs de la plate-forme.</p>
                                                    </Link>
                                                </Col>
                                                <Col md={4} sm={6}  xs={12}>
                                                    <Link to="/administration/headband" className="menu-item">
                                                        <h3>Mise en avant </h3>
                                                        <p>Mettre en avant un texte, des photos et des vidéos en haut du fil d’actualité sur la page d’accueil du web.</p>
                                                    </Link>
                                                </Col>
                                                <Col md={4} sm={6}  xs={12}>
                                                    <Link to="/administration/banners" className="menu-item">
                                                        <h3>Page d’accueil Web</h3>
                                                        <p>Placer une photo, un logo ou une vidéo…en haut de la colonne de droite uniquement sur le web.</p>
                                                    </Link>
                                                </Col> 
                                                <Col md={4} sm={6}  xs={12}>
                                                    <Link to="/administration/breaking-news" className="menu-item">
                                                        <h3>Breaking-News</h3>
                                                        <p>Permet de défiler des informations</p>
                                                    </Link>
                                                </Col>
                                                <Col md={4} sm={6}  xs={12}>
                                                    <Link to="/administration/links" className="menu-item">
                                                        <h3>Mes liens</h3>
                                                        <p>Permet de modifier Mes liens.</p>
                                                    </Link>
                                                </Col>
                                                <Col md={4} sm={6}  xs={12}>
                                                    <Link to="/administration/stats" className="menu-item">
                                                        <h3>Statistiques</h3>
                                                        <p>Consultez les inscriptions à la plate-forme</p>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }} />
                                <Route exact path="/administration/settings" component={Settings} />   
                                <Route exact path="/administration/headband" component={Headband} />   
                                <Route exact path="/administration/banners" component={Banners} />   
                                <Route exact path="/administration/breaking-news" component={BreakingNews} />   
                                <Route exact path="/administration/links" component={Links} />   
                                <Route       path="/administration/stats/" component={Statistics} />   
                            </Switch>
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default Administration