import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Global from '../../../inc/Global.js'
import configs from '../../../inc/helpers.js';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import Axios from 'axios';

import ChatList from '../../header/Chat'


class Item extends Component {

    constructor(){
        super()
        this.state = {
            userId: null,
            deleteUserConfirmation: false,
            deletedUsed: null
        }

        this.DeleteBtn = React.createRef();
    }

    deleteUser(){
        if(this.state.userId === null) return;

        this.DeleteBtn.current.classList.add('on');
        Axios
        .delete(Global.API_URL+"/api/userdelete/"+this.state.userId)
        .then((res) => {
            // console.log(res
            this.DeleteBtn.current.classList.remove('on');
            this.setState({
                userId: null,
                deleteUserConfirmation: false,
                deletedUsed: this.state.userId
            })
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            this.DeleteBtn.current.classList.remove('on');
        });
    }

    render() { 

        const { 
            ID,
            Name,
            LastName,
            Photo,
            Job,
            PhoneNum,
            Email,
            casecoche,
            // user
        } = this.props;
        
        const APIURL = Global.API_URL;
        
        const RandColor = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
        
        if( this.state.deletedUsed == ID ) return "";

        return (
            <li className="_ursl-listitem">
                <div className="header-post">
                    {configs.isAdmin() ?
                        <div className="rm-list">
                            <input type="checkbox" id={"user-"+ID} />
                            <label className="toggle" htmlFor={"user-"+ID}>...</label>
                            <div className="rm-list-item">
                                <ul>

                                    <li><Link to={{
                                        pathname : "/admin/utilisateurs/attribuer-des-communautes",
                                        state : {
                                            addedUser : ID,
                                            name : Name,
                                            lastname : LastName,
                                            email : Email,
                                            job : Job,
                                            tel : PhoneNum,
                                            photo : Photo,
                                            casecoche : casecoche
                                        }
                                    }}>Ajouter aux communautés</Link></li>
                                    <li><a href onClick={(e)=>{this.setState({deleteUserConfirmation: true, userId: ID})}} >Supprimer</a></li>
                                </ul>
                            </div>
                        </div>
                        : 
                        <br />
                    }
                    <div className="user-publisher">
                        <figure 
                            className="userimage" 
                            style={Photo ?
                                {backgroundImage: 'url('+ APIURL +  Photo + ')'}
                                : 
                                {}
                            } >
                                <Link to="" onClick={(e) => e.preventDefault()} className="a">
                                    {!Photo && LastName.charAt(0)+Name.charAt(0) }
                                </Link>
                        </figure>
                        <div className="user-infos">
                            <div className="username">
                                {LastName.toLowerCase()} {Name.toLowerCase()}
                            </div>
                            <div className="d-flex">
                                <ul>
                                    {
                                        Job &&
                                        <li><i className="fa fa-briefcase"></i> {Job}</li>
                                    }
                                    {
                                        Email &&
                                        <li><i className="fa fa-envelope"></i> <a target="_blank" href={`mailto:${Email}`}>{Email}</a></li>
                                    } 
                                    {
                                        PhoneNum &&
                                        <li><i className="fa fa-phone"></i> <a target="_blank" href={`tel:${PhoneNum}`}>{PhoneNum}</a></li>
                                    } 
                                </ul>
                                <div className="">
                                    {configs.isAdmin() &&
                                        <Link to={{
                                            pathname: '/admin/utilisateur/modifier',
                                            state: { 
                                                id :  ID,
                                                name : Name,
                                                lastname : LastName,
                                                job: Job,
                                                email : Email,
                                                tel : PhoneNum,
                                                photo : Photo,
                                                casecoche : casecoche,
                                            }
                                        }} className="button" >Modifier</Link>
                                    }
                                </div>
                            </div>
                            {/* <button className="start-chat" onClick={(e)=>{
                                let userToChat = user;
                                // userToChat.email = userToChat.Email
                                ChatList.startChatByUser(e, userToChat)
                                userToChat.popopen = false;
                            }}><i className="fa fa-comments"></i></button>    */}
                        </div>
                    </div>
                </div>  

                {
                    this.state.deleteUserConfirmation && 
                    <div className="_modal confirmation">
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</h2>
                                </div>
                                <div className="_modal-body">
                                <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                    <button onClick={()=>{this.setState({deleteUserConfirmation: false})}} className="button wt lg">Annuler</button>
                                    <button onClick={(e)=>{this.deleteUser(e)}} ref={this.DeleteBtn} className="button lg">
                                        <span className="fa fa-spin fa-spinner"></span> Supprimer
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </li>
        )
    }
}

Item.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth : state.auth
})

export default connect(mapStateToProps, {})(withRouter(Item));