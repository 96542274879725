import React, { useEffect, useState } from 'react'  
import Global from '../../../../inc/Global'
import configs from '../../../../inc/helpers';
import Axios from 'axios';  

//components
import {Form, Row, Col, Button, Dropdown} from 'react-bootstrap';  
import BeatLoader from "react-spinners/BeatLoader";


const Connexions = (props)=>{  
    
    const [stats, setStats] = useState([])
    const [isLoaded, setLoaded] = useState(false)

    useEffect(() => { 
        getStats()
    }, []); 

    const getStats = ()=>{
        Axios.get(Global.API_URL + "/api/admin/statconnexion").then((res) => {
            if( res.data.status ){
                 setStats(res.data.state)
                 setLoaded(true)
            }
        })
    } 

    return(
        <div className="stats stats-connexions"> 
            <h2>Connexions</h2>
            <p>ID, Nom, prénom, Email et dernière connexion.</p>

            {
                !isLoaded &&
                <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
            }
            
            {
                isLoaded &&
                <table>
                    <thead>
                        <tr> 
                            <th>Nom et Prénom</th>
                            <th>Email</th>
                            <th>Dernière connexion</th>
                            <th>Plate-forme</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats.sort((a,b)=>{return new Date(b.lastconnexion) - new Date(a.lastconnexion);}).map(item=>(
                            <tr> 
                                <td>{item.user?.profile?.prenom} {item.user?.profile?.nom}</td>
                                <td>{item.user.email}</td>
                                <td>{configs.timeSince(item.lastconnexion)}</td>
                                <td>{item.device}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    )
}

export default Connexions