import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Item from './Item'
import axios from 'axios'
import Global from '../../inc/Global.js'
import debounce from "lodash.debounce";
import SHA1 from 'sha1'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import PlusCircle from '../icons/PlusCircle'
import BeatLoader from "react-spinners/BeatLoader";
import '../../assets/css/communauties.css'
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import AddCommunity from './AddCommunity'
import configs from '../../inc/helpers'
const simpleTextSearch = require('simple-text-search')

class Communities extends Component {
    constructor(props){
        super(props)

        this.state = {
            communities : [],
            AllCommunities : [],
            otherCommunities : [],
            otherAllCommunities : [],
            limit : 10 ,
            step : 10,
            loaded : false,
            allLoaded : false,
            itemToDelete : null,
            newCommunity: true
        }

        this.LoadMore = React.createRef();
        this.DeleteBtn = React.createRef();
        this.searchB = React.createRef();

        this.handleJoinCommunity = this.handleJoinCommunity.bind(this);

        this.hdlist = [];
        
        window.onscroll = debounce(() => {
            // console.log(window.innerHeight + document.documentElement.scrollTop , document.documentElement.offsetHeight)
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
                
                this.setState({
                    limit : this.state.limit + this.state.step
                })
                if(this.state.limit >= this.state.communities.length){
                    this.setState({
                        allLoaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false
                    })
                }
            }
        }, 100);
    }
    
    componentDidMount(){
        const data = {
            nbCommunity : 10000,
            startPoint : 0,
            idUser : this.props.auth.user.user_id,
            type : '',
            hasNotif : true
        }
        axios
        .post(Global.API_URL+"/api/getcommunities", data)
        .then((res) => {
            var communities = [];
            res.data.forEach(function(item, key) {
                communities.push({
                    ID : item.id_group,
                    Color : item.couleur,
                    Invitation : item.invitation,
                    Logo : item.logo,
                    NBAdmin : item.nb_admin,
                    NBUser : item.nb_user,
                    Cover : item.photocouverture,
                    Subject : item.sujet,
                    Title : item.titre,
                    Type : item.typegroupe,
                    UserEmail : item.user_email,
                    UserID : item.user_id,
                    Role : item.user_role,
                    NbPostNonLu : item.nb_postnonlu,
                });
            });
            // res.data.secret.forEach(function(item, key) {
            //     communities.push({
            //         ID : item.id,
            //         Color : item.couleur,
            //         Invitation : item.invitation,
            //         Logo : item.logo,
            //         NBAdmin : item.nb_admin,
            //         NBUser : item.nb_user,
            //         Cover : item.photocouverture,
            //         Subject : item.sujet,
            //         Title : item.titre,
            //         Type : item.typegroupe,
            //         UserEmail : item.user_email,
            //         UserID : item.user_id,
            //         Role : item.user_role,
            //     });
            // });
            // res.data.public.forEach(function(item, key) {
            //     communities.push({
            //         ID : item.id,
            //         Color : item.couleur,
            //         Invitation : item.invitation,
            //         Logo : item.logo,
            //         NBAdmin : item.nb_admin,
            //         NBUser : item.nb_user,
            //         Cover : item.photocouverture,
            //         Subject : item.sujet,
            //         Title : item.titre,
            //         Type : item.typegroupe,
            //         UserEmail : item.user_email,
            //         UserID : item.user_id,
            //         Role : item.user_role,
            //     });
            // });
            // res.data.defaut.forEach(function(item, key) {
            //     communities.push({
            //         ID : item.id,
            //         Color : item.couleur,
            //         Invitation : item.invitation,
            //         Logo : item.logo,
            //         NBAdmin : item.nb_admin,
            //         NBUser : item.nb_user,
            //         Cover : item.photocouverture,
            //         Subject : item.sujet,
            //         Title : item.titre,
            //         Type : item.typegroupe,
            //         UserEmail : item.user_email,
            //         UserID : item.user_id,
            //         Role : item.user_role,
            //     });
            // });
            this.setState({
                communities : communities,
                AllCommunities : communities,
                loaded : true
            })
            // console.log(communities)
            if(this.state.limit >= communities.length){
                this.setState({
                    allLoaded : true
                })
            }else{
                this.setState({
                    allLoaded : false
                })
            }
            
        })
        .catch((err) => {
            // console.log(err.message);
        });

        // Other Communities
        axios
        .get(Global.API_URL+"/api/getothergroup?t="+SHA1(new Date()))
        .then((res) => {
            var otherCommunities = [];
            res.data.forEach(function(item, key) {
                otherCommunities.push({
                    ID : item.id_group,
                    Color : item.couleur,
                    Invitation : item.invitation,
                    Logo : item.logo,
                    NBAdmin : item.nb_admin,
                    NBUser : item.nb_user,
                    Cover : item.photocouverture,
                    Subject : item.sujet,
                    Title : item.titre,
                    Type : item.typegroupe,
                    UserEmail : item.user_email,
                    UserID : item.user_id,
                    Role : item.user_role,
                });
            });
            this.setState({
                otherCommunities : otherCommunities,
                otherAllCommunities : otherCommunities
            })
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }

    handleSearchInput= async (event) => {
        event.target.setAttribute('val', event.target.value);
        
        // console.log(this.hdlist[0]);
        if(event.target.value !== ""){
            this.hdlist[0].style.display = 'none';
            this.hdlist[1].style.display = 'none';
        }else{
            this.hdlist[0].style.display = 'block';
            this.hdlist[1].style.display = 'block';
        }

        const communities = this.state.AllCommunities.filter(item =>{
            let get = simpleTextSearch([item.Title, item.Subject, item.UserEmail])
            return get(event.target.value).length > 0
        });
        const otherCommunities = this.state.otherAllCommunities.filter(item =>{
            let get = simpleTextSearch([item.Title, item.Subject, item.UserEmail])
            return get(event.target.value).length > 0
        });
        await this.setState({ communities, otherCommunities }); 

        if(this.state.limit >= this.state.communities.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    handleDelete (e) {
        e.preventDefault();
        this.setState({
            itemToDelete : e.target.getAttribute('id')
        });
    }
    handleJoinCommunity(ev){
        var target = ev.target;
        if(target.hasAttribute('disabled')){
            return;
        }

        target.classList.add('on');
        const targetId = target.getAttribute('data-id');
        const targetType = target.getAttribute('data-type');
        
        var path
        if(targetType === 'public'){
            path = "/rejoindrepub"
        }else{
            path = "/rejoindreprivate"
        }
        const data = {
            email : this.props.auth.user.username,
            id: targetId
        } 

        axios
        .post(Global.API_URL+path, data)
        .then((res) => {
            if(res.data.message === "Demand sent"){
                target.innerHTML = "Demande envoyée"
            }
            target.classList.remove('on');
            target.setAttribute('disabled', true);
        }).catch((err) => {
            // console.log('Error : '+err.message)
            target.classList.remove('on');
        });
        
    }
    cancel (e){
        e.preventDefault();
        this.setState({
            itemToDelete : null
        });
    }
    confirmDeletion (e){
        e.preventDefault();
        this.DeleteBtn.current.classList.add('on');
        
        
        axios
        .delete(Global.API_URL+"/api/deletegroup/"+this.state.itemToDelete)
        .then((res) => {
            this.DeleteBtn.current.classList.remove('on');

            var communities = [...this.state.communities]; 
            var allCommunities = [...this.state.AllCommunities];
            var otherCommunities = [...this.state.otherCommunities]; 
            var otherAllCommunities = [...this.state.otherAllCommunities];
    
            var currentComm = null;
            var currentAllComm = null;
            var othercurrentComm = null;
            var othercurrentAllComm = null;
            
            communities.forEach(function(el, ind){
                
                if(el.ID === parseInt(this.state.itemToDelete)){
                    currentComm = ind;
                }
            }.bind(this));

            otherCommunities.forEach(function(el, ind){
                
                if(el.ID === parseInt(this.state.itemToDelete)){
                    othercurrentComm = ind;
                }
            }.bind(this));
    
            allCommunities.forEach(function(el, ind){
    
                if(el.ID === parseInt(this.state.itemToDelete)){
                    currentAllComm = ind; 
                }
            }.bind(this));
            otherAllCommunities.forEach(function(el, ind){
    
                if(el.ID === parseInt(this.state.itemToDelete)){
                    othercurrentAllComm = ind; 
                }
            }.bind(this));

            communities.splice(currentComm, 1)
            allCommunities.splice(currentAllComm, 1)
            
            communities.splice(othercurrentComm, 1)
            allCommunities.splice(othercurrentAllComm, 1)
            
            this.setState({
                itemToDelete : null,
                communities : communities,
                otherCommunities : otherCommunities,
                AllCommunities : allCommunities,
                otherAllCommunities : otherAllCommunities,
            });
            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            this.DeleteBtn.current.classList.remove('on');
            this.setState({
                itemToDelete : null
            });
        });
        
        
    }
    render() {
        return (
            <section className="content">
                <div className="wrap">
                    <div className="communaute-list">
                        <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3>Communautés</h3>
                                {
                                    (configs.canCreateCommunity() || configs.isAdmin()) &&
                                    <div className=" ">
                                        <ul>
                                            <li>
                                                <Link to="/communautes/ajouter">Créer une communauté <PlusCircle /></Link>
                                            </li>
                                            {/* <li>
                                                <Link to="" onClick={(e)=> {e.preventDefault(); this.searchB.current.focus()}}><span className="fa fa-search"></span></Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="_srs-users">
                            <input val="" ref={this.searchB} type="search" name="s" onChange={this.handleSearchInput} placeholder="Rechercher" />
                            <Link   to=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.searchB.current.setAttribute('val', ""); 
                                        this.searchB.current.value = ""; 
                                        this.setState({communities : this.state.AllCommunities
                                    })}} 
                                    className="button">Annuler</Link>
                        </div>

                        {
                            this.state.itemToDelete && 
                            <div className="_modal confirmation">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Êtes-vous sûr de vouloir supprimer cette communauté&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                        <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                            <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                            <button onClick={this.confirmDeletion.bind(this)} ref={this.DeleteBtn} className="button lg">
                                                <span className="fa fa-spin fa-spinner"></span> Supprimer
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {
                            !this.state.loaded &&
                            <center style={{padding: 0, fontSize: 20}}>
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            </center>
                        }
                        <br/>
                        {
                            this.state.loaded &&
                            <div className="header-list" ref={(ref) => this.hdlist[0] = ref}>
                                <div className="d-flex jc-space-b align-center">
                                    <h3>Mes communautés ({this.state.communities.length})</h3>
                                    <div className=""></div>
                                </div>
                            </div>
                        }
                        
                        
                        <div className="_ursl _cmlt">
                            <ul>
                                {/* The below comment is with Paginations */}
                                {/* {this.state.communities.slice(0, this.state.limit).map((community, i) => */}
                                {this.state.communities.map((community, i) =>
                                    <Item 
                                        key         ={community.ID}
                                        ID          ={community.ID}
                                        Color       ={community.Color}
                                        Invitation  ={community.Invitation}
                                        Logo        ={community.Logo}
                                        NBAdmin     ={community.NBAdmin}
                                        NBUser      ={community.NBUser}
                                        Cover       ={community.Cover}
                                        Subject     ={community.Subject} 
                                        Title       ={community.Title} 
                                        Type        ={community.Type} 
                                        UserEmail   ={community.UserEmail}
                                        UserID      ={community.UserID} 
                                        Role        ={community.Role}
                                        NbPostNonLu ={community.NbPostNonLu ? community.NbPostNonLu : 0} 
                                        isMyCommunity = {true}
                                        handleDelete={this.handleDelete.bind(this)} />
                                )}
                            </ul>
                        </div>
                        {/* <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de communautés</span>
                                :
                                <span className="fa fa-spinner fa-spin"></span>
                            }
                        </div> */}
                        <br /> 
                        <br /> 
                        {
                            this.state.loaded &&
                            <div className="header-list" ref={(ref) => this.hdlist[1] = ref}>
                                <div className="d-flex jc-space-b align-center">
                                    <h3>Autres communautés ({this.state.otherCommunities.length})</h3>
                                    <div className="">
                                    </div>
                                </div>
                            </div> 
                        }
                        
                        <div className="_ursl _cmlt">
                            <ul>
                                {/* The below comment is with Paginations */}
                                {/* {this.state.communities.slice(0, this.state.limit).map((community, i) => */}
                                {this.state.otherCommunities.map((community, i) =>
                                    <Item 
                                        key         ={community.ID}
                                        ID          ={community.ID}
                                        Color       ={community.Color}
                                        Invitation  ={community.Invitation}
                                        Logo        ={community.Logo}
                                        NBAdmin     ={community.NBAdmin}
                                        NBUser      ={community.NBUser}
                                        Cover       ={community.Cover}
                                        Subject     ={community.Subject} 
                                        Title       ={community.Title} 
                                        Type        ={community.Type} 
                                        UserEmail   ={community.UserEmail} 
                                        UserID      ={community.UserID} 
                                        Role        ={community.Role}
                                        isMyCommunity = {false}
                                        handleDelete={this.handleDelete.bind(this)}
                                        handleJoinCommunity={this.handleJoinCommunity} />
                                )}
                            </ul>
                        </div>
                    </div>
                </div>



                {/* popup create communaute */}

                
                <Switch>
                    <Route path="/communautes/ajouter" component={()=>{
                        return( 
                            <div className="_modal new-community">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Création communauté</h2>
                                        </div>
                                        <AddCommunity />
                                    </div>
                                </div>
                            </div>
                        )
                    }} />
                </Switch> 
            </section>
        )
    }
}



Communities.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(Communities));