import React, { useState } from 'react';
import { Document, Page } from  'react-pdf/dist/esm/entry.webpack';
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import {connect} from 'react-redux'

import Global from '../../inc/Global.js';



 function PdfViewer() {
  const [numPages, setNumPages] = useState(null);
  const [scal, setScal] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  function scalPlus() {
    // setScal(scal+0.2);
    // https://api.waldev.wearelinked.fr/security
    axios
    .post(Global.API_URL+"/api/security", {
        "idgroup":274,
        "idpost":2432
    })
    .then((res)=>{
        console.log(res);
    })
  }
//   function scalMoin() {
//     setScal(scal-0.2);
//   }

  return (
    <>
      <button
            style=
            {{width: "150px",
            height:"50px",
            fontSize: "25px",
            borderRadius: "10px",
            border: "none",
            background: '#fff',
            position: 'fixed',
            transform: 'translate(-50%,-50%)',
            top:' 50%',
            left:' 50%',
            cursor:'pointer',
        }}
            
          type="button"
          onClick={scalPlus}
        >
            download 
        </button>

    </>
  );
}


  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(PdfViewer));
