import React, { Component } from 'react'
import Global from '../../../inc/Global';
import configs from '../../../inc/helpers'
export default class FilesView extends Component {
    
    constructor(props){
        super(props);
        if(!this.props.files || this.props.files.length === 0){
            this.state = {
                files : []
            }
            return;
        }
        
        
        this.state = {
            files : this.props.files,
            filesName : this.props.filesName
        }


    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            files : nextProps.files
        })
    }
    render() {
        if(!this.props.files || this.props.files.length === 0){
            return "";
        }
        const {files,filesName} = this.state;

        var filesData = [];
        for(var $i = 0; $i < files.length; $i++){
            var Ext = configs.getExtention(files[$i]);  
            if (Ext!='m4a' && Ext!='mp3'&& Ext!='aac'&& Ext!='m4b'&& Ext!='opus' &&  Ext != 'wav') {
                var file = {};
                file['icon'] = configs.GetImage(configs.GetFileIcon(Ext));
                file['filename'] = filesName[$i];   
                file['link'] = Global.API_URL +"/" + files[$i];   
                filesData.push(file);
            }
        }
        


        return (
            <div className="file">
                {filesData.map((file, index) => (

                <div key={"file-"+index} className="d-flex align-center">
                    <div className="file-icon">
                        <a download href={file.link} target="_blank"><img src={file.icon} alt="" /></a>
                    </div>
                    <div className="file-name">
                        <a download href={file.link} target="_blank"><span>{file.filename}</span></a>
                    </div>
                </div>
                ))}
            </div>
        )
    }
}
