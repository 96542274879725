import React, { Component } from 'react'
import Validation from '../../../inc/Validation';

export default class VideoView extends Component {
    render() {
        const { videoUrl, poster, autoPlay } = this.props;
        
        if(!videoUrl || !Validation.Url(videoUrl)) {
            return "";
        }

        return (
            <div className="video">
                <video controls src={videoUrl} poster={poster}  autoPlay={autoPlay}>
                    <source src={videoUrl} type="video/mp4"/>
                    <source src={videoUrl} type="video/webm" />
                    <source src={videoUrl} type="video/ogg" />
                </video>
            </div>
        )
    }
}
