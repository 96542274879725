import React, { Component } from 'react'
import logo from '../../assets/img/white-logo.png'
import Validation from '../../inc/Validation'
import InputField from '../InputField'
import classnames from 'classnames'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios'
import Global from '../../inc/Global'
import SHA1 from 'sha1';
import {Link} from 'react-router-dom'


class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newpassword: "",
            repeatpassword: "",
            errors: {
                newpassword : true,
                repeatpassword : true
            },
            PasswordIsNotMatched: false,
            PasswordIsIncorrect: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(e) {
        this.setState({
          [e.target.name]: e.target.value,
        });
        if(!Validation.Password(e.target.value)){
            this.setState({
                errors : {
                    [e.target.name]: true,
                }
            })
        }else{
            this.setState({
                errors : {
                    [e.target.name]: false,
                }
            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const passwords = {
            newpassword: this.state.newpassword,
            repeatpassword: this.state.repeatpassword,
        };

        if(!this.state.errors.newpassword && !this.state.errors.repeatpassword ){
            if(!Validation.CfrPassword(passwords.newpassword, passwords.repeatpassword)){
                this.setState({
                    PasswordIsNotMatched : true,
                    PasswordIsIncorrect : false
                })
            }else{
                this.setState({
                    PasswordIsNotMatched : false,
                    PasswordIsIncorrect : false
                })
                
                const data = {
                    plainPassword:{
                       first: SHA1(passwords.newpassword),
                       second: SHA1(passwords.repeatpassword)
                    }
                }
                axios
                .post(Global.API_URL+"/api/reset", data)
                .then((res) => {
                    // console.log(res);
                    
                    this.props.history.push('/terminer-inscription/felicitations');
                })
                .catch((err) => {
                    // console.log('Une erreur s\'est produite lors de la réinitialisation du mot de passe, veuillez contacter l\'administrateur');
                });
            }
        }else{
            this.setState({
                PasswordIsIncorrect : true,
                PasswordIsNotMatched : false
            })
        }
    }
    
    render() {
        const { errors, PasswordIsNotMatched, PasswordIsIncorrect} = this.state;
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="">
                                            <center className="logo-with-hashtag">
                                                <img src={Global.APP_LOGO} alt="" />
                                            </center>
                                            <form id="_login_Form" onSubmit={this.handleSubmit}>
                                                <div className="bg-white-transparent text-center"> 
                                                    <div className="entry-welcome">
                                                        <div className="p">
                                                            <p>Pour plus de sécurité et de confidentialité,
                                                                choisissez votre mot de passe (minimum 8 caractères,
                                                                un chiffre et une lettre majuscule).
                                                            </p>
                                                        </div>
                                                        
                                                        <InputField 
                                                            type="password" 
                                                            id="_newpassword" 
                                                            name="newpassword" 
                                                            label="Le mot de passe" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                "redFieldError": errors.newpassword,
                                                            })} 
                                                            value={this.state.newpassword} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "Mot de passe est incorrect": errors.newpassword,
                                                            })} />

                                                        <InputField 
                                                            type="password" 
                                                            id="_repeatpassword" 
                                                            name="repeatpassword" 
                                                            label="Confirmer le mot de passe" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                "redFieldError": errors.repeatpassword,
                                                            })}  
                                                            value={this.state.repeatpassword} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "Confirmer mot de passe est incorrect": errors.repeatpassword,
                                                            })} />

                                                        {PasswordIsNotMatched && (
                                                            <div className="_alert _alert-danger">Le mot de passe ne correspond pas</div>
                                                        )}
                                                        {PasswordIsIncorrect && (
                                                            <div className="_alert _alert-danger">Erreur : Veuillez vérifier le mot de passe</div>
                                                        )}
                                                            
                                                        <br />
                                                        <br />
                                                    </div>
                                                    <div className="_cnu-footer-tabs footer-fixed m-t-10">
                                                        <div className="d-flex jc-space-b">
                                                            <Link to="/terminer-inscription/reinitialiser-motdepasse" className="button lg icon wt">
                                                                <i className="fa fa-chevron-left"></i> ANNULER
                                                            </Link>
                                                            <button type="submit" className="button lg icon"> 
                                                                RÉINITIALISER&nbsp; 
                                                                 <i className="fa fa-chevron-right"></i>
                                                            </button>
                                                        </div>
                                                    </div> 
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ResetPassword.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(ResetPassword);