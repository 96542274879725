import React, { Component } from 'react'
import configs from '../../../inc/helpers'
import '../../../assets/css/user.css'
import InputField from '../../InputField'
import Validator from '../../../inc/Validation'
import axios from 'axios'
import Global from '../../../inc/Global.js'
import classnames from 'classnames'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dropzone from 'react-dropzone';
import jwt_decode from 'jwt-decode'
import { base64StringtoFile, extractImageFileExtensionFromBase64, image64toCanvasRef} from '../../../inc/ImageLib';

const imageMaxSize = 5242880 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})


class AddUser extends Component {

    constructor(props){
        super(props)

        

        this.state = {
            id: "",
            name: "",
            lastname: "",
            email: "",
            job: "",
            phone: "",
            photo: "",
            errors: {},
            createCommunity : false,
            HasNotLoggedIn: false,
            dateIsCorrect : "",
            imgSrc : null,
            dragIsActive : false,
            imgSrcExt: null,
            profilSrc : "",
            imgFile : null,
            orgWidthAspect: null,
            isExist: false,
            crop :{ 
                aspect: 1/1,
                unit: '%'
            }
        };

        this.canvasRef = React.createRef(null);
        this.acceptBtn = React.createRef(null);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onRadioButtonChange = this.onRadioButtonChange.bind(this);
        
    }

    handleInputChange (e){
        // console.log(e)
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    onRadioButtonChange (e){
        e.preventDefault();
        this.setState({
            createCommunity : !this.state.createCommunity
        });
    }
    handleSubmit (event){
        event.preventDefault(); 
        
        var Valid = true;
        var errors = {
            name : false,
            lastname : false,
            job : false,
            email : false,
            phone : false,
        };
         
        if(!Validator.Email(this.state.email)){
            Valid = false;
            errors['email'] = true;
        }else{
            errors['email'] = false;
        }
        
        if(!Validator.PhoneNumber(this.state.phone) && !Validator.Empty(this.state.phone)){
            Valid = false;
            errors['phone'] = true;
        }else{
            errors['phone'] = false;
        }

        this.setState({
            errors : errors
        })


        if(Valid){
            const  data = {
                email: this.state.email,
            }
            this.acceptBtn.current.classList.add('on');
            axios
            .post(Global.API_URL+"/api/createUser", data)
            .then((res) => {
                // console.log(res.data.id);
                if(res.data.id){
                    this.setState({id : res.data.id});

                    const userDT = {
                        user:{
                            email: this.state.email,
                            id: res.data.id
                        },
                        profile:{
                            fonction: this.state.job,
                            nom: this.state.lastname,
                            prenom: this.state.name,
                            telephone: this.state.phone,
                            casecoche: this.state.createCommunity,
                        }
                    }
                    axios
                    .put(Global.API_URL+"/api/admin/update", userDT)
                    .then((dt) => {
                        if(dt.status){
                            this.handleAcceptButton(event)
                        }
                    })
                    .catch((err) => {
                        // console.log("has error !!")
                    })
                    this.setState({
                        isExist : false
                    });
                }else{
                    this.setState({
                        isExist : true
                    });
                    this.acceptBtn.current.classList.remove('on');
                }
            })
            .catch((err) => {
                // console.log(err.message)
            });
        }
    }
    
    handleOnCropChange(crop){
        crop.aspect = 1;
        this.setState({crop:crop});
    }
    handleSaveClick = async event => {
        event.preventDefault()
        event.target.classList.add('on');
        
        const {imgSrc}  = this.state
        if (imgSrc) {
        
            const canvasRef = this.canvasRef.current
        
            const {imgSrcExt} =  this.state
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)

            const decode = jwt_decode(localStorage.jwtToken)
            const myFilename = "profile-"+ decode.username +"." + imgSrcExt

            // file to be uploaded
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            
            
            await this.setState({
                profilSrc : imageData64,
                imgFile : myNewCroppedFile,
                imgSrc : null,
            }); 
            // console.log(this.state.imgFile);
           
        }
    }

    handleAcceptButton = (event) => {  
        var data = new FormData();
        data.append('image',this.state.imgFile);
        
        axios
        .post(Global.API_URL+"/api/profil/imagewithemail/"+this.state.email, 
            data, 
            { headers: {
                'content-type': 'multipart/form-data'
                }
            }
        )
        .then((res) => {
            // console.log(res)
            this.acceptBtn.current.classList.remove('on');
            this.props.history.push({
                pathname: '/admin/utilisateurs/attribuer-des-communautes',
                state: { 
                    addedUser: this.state.id, 
                    email : this.state.email,
                    name : this.state.name,
                    lastname : this.state.lastname,
                    job: this.state.job,
                    tel : this.state.phone,
                    photo : this.state.photo,
                    casecoche : this.state.createCommunity,
                }
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            // $target.classList.remove('on');
        });
    }
    handleOnCropComplete = (crop, percentCrop) =>{
        const canvasRef = this.canvasRef.current
        const {imgSrc}  = this.state
        
        const newCrop = {
          axpect : 1,
          x: (crop.x * this.state.orgWidthAspect),
          y: (crop.y * this.state.orgWidthAspect),
          width: (crop.width * this.state.orgWidthAspect),
          height: (crop.height * this.state.orgWidthAspect)
        };
        image64toCanvasRef(canvasRef, imgSrc, newCrop)
    }
    handleImageLoaded = async image => {
        await this.setState({
            orgWidthAspect: image.naturalWidth / image.width
        });

        
        if(image.width > image.height){
            await this.setState({ crop: {width: (image.height - 50), height: (image.height - 50), x :25, y: 25 } });
        }else{
            await this.setState({ crop: {width: (image.width - 50), height: (image.width - 50), x :25, y: 25 } });
        }
        
        const canvasRef = this.canvasRef.current
        const {imgSrc}  = this.state
        
        const newCrop = {
            axpect : 1,
            x: (this.state.crop.x * this.state.orgWidthAspect),
            y: (this.state.crop.y * this.state.orgWidthAspect),
            width: (this.state.crop.width * this.state.orgWidthAspect),
            height: (this.state.crop.height * this.state.orgWidthAspect)
        };
        
        image64toCanvasRef(canvasRef, imgSrc, newCrop)

        return false; // Return false when setting crop state in here.
    };
    
    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.file.type
            const currentFileSize = currentFile.file.size
            
            if(currentFileSize > imageMaxSize) {
                alert("Ce fichier n'est pas autorisé, " + currentFileSize + " octets est trop grand")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("Ce fichier n'est pas autorisé. Seules les images sont autorisées.")
                return false
            }
            return true
        }
    }
    
    handleOndrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0){
            this.verifyFile(rejectedFiles)
        }
        
        if (files && files.length > 0){
            //const isVerified = this.verifyFile(files)
        
            // imageBase64Data 
            const currentFile = files[0]
            const myFileItemReader = new FileReader()
            
            myFileItemReader.addEventListener("load", ()=>{
                
                const myResult = myFileItemReader.result
                
                this.setState({
                    imgSrc: myResult,
                    imgSrcExt: extractImageFileExtensionFromBase64(myResult),
                    dragIsActive : false
                })

                
            }, false)
            
            myFileItemReader.readAsDataURL(currentFile)
            
        }
    }
    
    handleOnDragEnter = (DragEvent) => {
        this.setState({dragIsActive : true});
    }
    handleOnDragLeave = (DragEvent) => {
        this.setState({dragIsActive : false});
    }

    render() {
        
        
        return (
            <div className="_modal new-community">
                <div className="_overlay"></div>
                <div className="_modal-wrap">
                    <div className="content">
                        <div className="_modal-content">
                        <div className="_modal-header">
                            <h2>Création utilisateur</h2>
                        </div>
                        <div className="_modal-body">
                            <section className="content">
                                <div className="wrap">
                                    <div className="add-user">
                                        <form action="" onSubmit={this.handleSubmit}>
                                            <div className="form">
                                                <InputField 
                                                    type="text" 
                                                    id="_name" 
                                                    name="name" 
                                                    label="Prénom" 
                                                    fixedLabel="false" 
                                                    classInput="" 
                                                    classContainer={classnames({
                                                        " hasError ": this.state.errors.name,
                                                    })} 
                                                    value={this.state.name} 
                                                    oninput={this.handleInputChange} 
                                                    errorMsg={classnames({
                                                        "Le prénom est obligatoire": this.state.errors.name,
                                                    })} />

                                                <InputField 
                                                    type="text" 
                                                    id="_lastname" 
                                                    name="lastname" 
                                                    label="Nom" 
                                                    fixedLabel="false" 
                                                    classInput="" 
                                                    classContainer={classnames({
                                                        " hasError ": this.state.errors.lastname,
                                                    })} 
                                                    value={this.state.lastname} 
                                                    oninput={this.handleInputChange} 
                                                    errorMsg={classnames({
                                                        "Le nom est obligatoire": this.state.errors.lastname,
                                                    })} />
                                                
                                                
                                                <InputField 
                                                    type="text" 
                                                    id="_email" 
                                                    name="email" 
                                                    label="Email" 
                                                    fixedLabel="false" 
                                                    classInput="" 
                                                    classContainer={classnames({
                                                        " hasError ": this.state.errors.email || this.state.isExist,
                                                    })} 
                                                    value={this.state.email} 
                                                    oninput={this.handleInputChange} 
                                                    errorMsg={classnames({
                                                        "l'email est obligatoire et il doit respecter ce format example@email.com<br>": this.state.errors.email,
                                                        "Cette adresse email est déjà associée un compte" : !this.state.errors.email && this.state.isExist
                                                    })} /> 
                                                
                                                <InputField 
                                                    type="text" 
                                                    id="_job" 
                                                    name="job" 
                                                    label="Fonction" 
                                                    fixedLabel="false" 
                                                    classInput="" 
                                                    classContainer=""
                                                    value={this.state.job} 
                                                    oninput={this.handleInputChange} 
                                                    errorMsg="" />

                                                <InputField 
                                                    type="text" 
                                                    id="_phone" 
                                                    name="phone" 
                                                    label="Téléphone" 
                                                    fixedLabel="false" 
                                                    classInput="" 
                                                    classContainer={classnames({
                                                        " hasError ": this.state.errors.phone,
                                                    })}  
                                                    value={this.state.phone} 
                                                    oninput={this.handleInputChange} 
                                                    errorMsg={classnames({
                                                        "Le téléphone est invalide (0X00000000 ou +XXX0000000)": this.state.errors.phone,
                                                    })} />

                                                    <div className="setting-rad d-flex jc-space-b align-center p-20">
                                                        <label htmlFor="_anonyme-parti">Autorisé à créer des communautés</label>

                                                        <div className="param d-flex align-center">
                                                            <div className="radio-button">
                                                                <input type="checkbox" onChange={(e) => {this.setState({createCommunity: !this.state.createCommunity})}} name="_anonyme-parti" id="_anonyme-parti" checked={this.state.createCommunity} />
                                                                <label htmlFor="_anonyme-parti">
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                <div className={this.state.dragIsActive ? "tab _p-photo d-flex align-center jc-center on" : "tab _p-photo d-flex align-center jc-center"}>
                                                    <div className="select-profil">
                                                        {this.state.imgSrc && 
                                                            <div className="_modal" id="CropImageModal">
                                                                <div className="_overlay"></div>
                                                                <div className="_modal-wrap">
                                                                    <div className="_modal-content">
                                                                        <div className="_modal-header">
                                                                            <h2>Recadrer l'image</h2>
                                                                        </div>
                                                                        <div className="_modal-body">
                                                                            <div className="d-flex f-wrap"> 
                                                                                <div className="rightSidebar">
                                                                                    <canvas ref={this.canvasRef}></canvas>
                                                                                </div>
                                                                                <div className="_modal-body-wrap crop">
                                                                                    <ReactCrop 
                                                                                        src={this.state.imgSrc} 
                                                                                        crop={this.state.crop} 
                                                                                        onChange={this.handleOnCropChange.bind(this)} 
                                                                                        onComplete={this.handleOnCropComplete} 
                                                                                        onImageLoaded={this.handleImageLoaded}
                                                                                        />
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="_modal-footer text-right">
                                                                            <span onClick={this.handleSaveClick} className="button lg"><span className="fa fa-spinner fa-spin"></span> Recadrer</span>
                                                                        </div>
                                                                    </div>   
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                        <Dropzone 
                                                            accept={acceptedFileTypes} 
                                                            onDrop={this.handleOndrop} 
                                                            multiple={false} 
                                                            maxSize={imageMaxSize}
                                                            onDragEnter={this.handleOnDragEnter}
                                                            onDragLeave={this.handleOnDragLeave} >
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                                
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                        <span  className="img" 
                                                                                style={this.state.profilSrc ? {backgroundImage: 'url('+this.state.profilSrc+')' } : {} }>
                                                                            {
                                                                                !this.state.profilSrc &&
                                                                                <span className="custom-icon custom-icon-user"></span>
                                                                            }
                                                                            
                                                                        </span>
                                                                    <p style={{maxWidth : '320px'}}>Faites glisser et déposez une photo de profil ici, ou cliquez pour la sélectionner</p>
                                                                    <label className="label">Charger une photo</label>
                                                                </div>
                                                            </section>
                                                        )}
                                                        </Dropzone>
                                                    </div>
                                                </div> 
                                            </div> 
                                        </form>
                                    </div>
                                </div>
                            </section> 
                        </div>
                        <div className="_modal-footer">
                            <div className="d-flex jc-space-b m-t-10 ">
                                <input onClick={(e) => {e.preventDefault(); this.props.history.goBack()}} type="reset" className="button wt lg" value="Annuler" />
                                <button ref={this.acceptBtn} onClick={this.handleSubmit} type="button" className="button lg" ><span className="fa fa-spin fa-spinner"></span> Continuer</button>
                            </div>
                        </div>
                    </div> 
                    </div>
                </div>
            </div>
        )
    }
}
export default AddUser;