import config from '../config.json'
import firebase from 'firebase/app';  
import 'firebase/messaging';






export const getTokenDevice = () => {
    try {
        if (firebase.apps.length === 0) {
            firebase.initializeApp(config.FIRE_BASE_CONFIG);
        }
        const messaging = firebase.messaging(); 
        
        return new Promise((resolve, reject)=>{
            messaging.getToken({vapidKey: config.FIRE_BASE_CONFIG.vapidkey}).then((tokenDevice) => {
                if (tokenDevice) {
                    console.log(tokenDevice); 
                resolve({
                    success: true,
                    token: tokenDevice
                })
                } else {
                    resolve({
                        success: false
                    })
                }
            }).catch((err) => { 
                resolve({
                    success: false
                })
            });  
        })
    } catch (error) {
        return new Promise((resolve, reject)=>{
            resolve({
                success: false
            })
        })
    } 
}


export const onMessageListener = () => { 
    try {
        if (!firebase.apps.length) {
            firebase.initializeApp(config.FIRE_BASE_CONFIG);
        }
        const messaging = firebase.messaging(); 
        return messaging
        
    }catch (error) {
        console.log("error =>",error);
        return false    
    } 
} 