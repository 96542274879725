import Axios from 'axios'
import React, { useRef, useState } from 'react'
import Global from '../../inc/Global'
import InputField from '../InputField'
import classnames from "classnames";
import TextareaAutosize from 'react-textarea-autosize'; 

const AddPoll = (props) => { 
    
    const RoleSelect = props.RoleSelect

    const [question, setQuestion] = useState("")
    const [communityID, setCommunityID] = useState(props.communityID) 
    const [communityIsSelected, setCommunityIsSelected] = useState(true) 
    // responses
    const [responses, setResponses] = useState(['',''])

    // date
    const [endDate, setEndDate] = useState(new Date())

    //image
    const inputImage = useRef();
    const [selectedImage, setSelectedImage] =  useState(null)

    // Upload progress
    const [uploadProgress, setUploadProgress] = useState(0)

    // Error
    const [errorMsg, setErrorMsg] = useState(null)


    const create = ()=>{

        if( communityID === 0 ){
            setCommunityIsSelected(false)
            setErrorMsg("Veuillez sélectionner une communauté.");
            return
        }else{
            setCommunityIsSelected(true)
        }

        if(question.trim() == ''){
            setErrorMsg("Veuillez saisir la question.");
            return
        }

        if(responses[0].trim() == '' || responses[1].trim() == ''){
            setErrorMsg("Veuillez remplir au moins deux reponses.");
            return
        } 

        setErrorMsg(null)

        let data = new FormData();
        data.append('question', question)
        data.append('time', new Date(endDate).toISOString())
        data.append('idgroup', communityID) 
        responses.map(res=>{
            if(res.trim() !== ''){
                data.append('reponses[]', res)
            }
        })
        if(selectedImage) data.append('file', selectedImage)

        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
        
                if( percent <= 100 ){
                    setUploadProgress(percent)
                }
            },
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        Axios
        .post(Global.API_URL+'/api/poll', data, options)
        .then((res) => {
            if(res.data.status){
                props.resetFotm()
                props.onSubmit({
                    data:[{post: res.data.poll}]
                })
            }
        }) 
    }

    return(
        <div 
            className={`create-post  add-new-poll active ${props.communityID !== 0 ? 'create-community-post' : ''}`}>
            <span className="trs-color"></span>
            <span className="cancel-post" onClick={()=>{props.resetFotm()}}>
                <i className="fa fa-close"></i>
            </span>  
            { props.communityID === 0 ? 
                <div className="title-create-post">
                    <div className="jc-space-b align-center f-wrap-xs">
                        <div className=""> 
                                <InputField
                                    type="select" 
                                    id={"_community"}
                                    name="community" 
                                    label="" 
                                    fixedLabel="true" 
                                    placeholder=""
                                    items={RoleSelect.items}
                                    classInput="" 
                                    classContainer={!communityIsSelected ? "error" : "" } 
                                    value={RoleSelect.selectedRole}  
                                    default={RoleSelect.selectedRole} 
                                    oninput={(e)=>{setCommunityID(e.value); setCommunityIsSelected(true); setErrorMsg(null)}} 
                                    errorMsg="" /> 
                        </div>
                    </div>
                </div>
                : 

                <input 
                    type="hidden"
                    id={"_community"}
                    name="community"
                    value={communityID}
                /> 

                }
            <div className="content-post"> 
                
                
                <div className="textarea"> 
                    <div className="wrapper"> 
                        {
                            selectedImage &&
                            <div className="poll-image">
                                <img src={URL.createObjectURL(selectedImage)} alt="" />
                                <button onClick={()=>{setSelectedImage(null)}}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        } 

                        <TextareaAutosize   
                            value={question} 
                            onChange={(e)=>{setQuestion(e.target.value)}} 
                            rows="1" autoFocus 
                            placeholder="Poser une question" 
                            className="text-post" 
                            />

                        <span className="figure" style={{backgroundImage: 'url('+ Global.API_URL  + props.auth.user.profil +')' }}>
                            { props.auth.user.profil === '0' ? props.auth.user.name.charAt(0) + props.auth.user.lastname.charAt(0) : ''} 
                        </span>
                    </div> 
                </div>  
                <div className="responses">
                    <ul>
                        {responses.map((response, index) =>{
                            return(
                                <li className="d-flex" key={index}>
                                    <div className="input-wrapper">
                                        <input
                                        value={response} 
                                        onChange={(e)=>{
                                            let _responses = [...responses];
                                            _responses[index] = e.target.value; 
                                            setResponses(_responses)
                                        }}
                                        type="text" 
                                        placeholder={`Réponse ${index+1}`}
                                        name={`response-${index}`} />
                                    </div>
                                    <div className="delete-btn">
                                        {
                                            (index >= 2 || responses.length > 2) &&
                                            <button
                                            onClick={()=>{
                                                let _responses = [...responses];
                                                _responses.splice(index, 1) 
                                                setResponses(_responses) 
                                                
                                            }}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        }
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <div>
                        <button className="add-response" onClick={()=>{setResponses([...responses].concat(['']));}}>
                            <i>+</i>
                            Ajouter une réponse
                        </button>
                    </div>
                    <div className="date-end-poll">
                        <label>Date clôture du sondage</label>
                        <InputField 
                            type="date" 
                            popperPlacement="top-start"
                            id="dateEnd" 
                            name="dateEnd"  
                            fixedLabel="false" 
                            classInput=""  
                            classContainer={""} 
                            value={endDate} 
                            minDate={new Date()}
                            oninput={(date)=>{setEndDate(date)}}  />
                    </div>
                    {
                        errorMsg &&
                        <div className="error-msg"> {errorMsg} </div>
                    }  
                    
                </div>
                {
                    uploadProgress > 0 && selectedImage &&
                    <div className="loaderProgress">
                        <div className="progressBar">
                            <span style={{width: uploadProgress + "%"}}></span>
                        </div>
                    </div>
                } 
                </div><div className="ft-create-post eventSection">
                    <button className="back-to-post" onClick={()=>{props.hidePollForm()}}>
                        <span className="fa fa-arrow-left"></span> 
                        Publication
                    </button>
                    <ul> 
                        <li>
                            <a href>
                                <label htmlFor="uploadImages">
                                    <span className="fa fa-picture-o"></span>
                                    <input 
                                        ref={inputImage}
                                        type="file" 
                                        name="uploadImages[]" 
                                        id="uploadImages" 
                                        accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif" 
                                        onChange={(e)=>{setSelectedImage(e.target.files[0])}} />
                                    Ajouter une photo
                                </label>
                            </a>
                        </li> 
                        <li>
                            <button onClick={()=>{create()}} className="button"><span>Publier</span></button>
                        </li>
                    </ul>
                </div>
            
        </div>
    )
}

export default AddPoll