import React, { Component, useCallback } from 'react'
//import { base64StringtoFile, extractImageFileExtensionFromBase64, image64toCanvasRef} from '../../inc/ImageLib';
import InputField from '../InputField';
import {Link} from 'react-router-dom'
import configs from '../../inc/helpers'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import axios from 'axios'
import Global from '../../inc/Global';
import Picker from 'react-giphy-component'
import PickerEmoji from 'emoji-picker-react';
import CustomLinkPreview from './template-parts/inc/CustomLinkPreview';
import { animateScroll } from "react-scroll";
import AddPoll from './AddPoll';

import Editor from '@draft-js-plugins/editor';

import createMentionPlugin ,{
    defaultSuggestionsFilter,
  }from '@draft-js-plugins/mention';
import { EditorState , convertToRaw ,ContentState,SelectionState,Modifier,setEditorState} from 'draft-js';
import '@draft-js-plugins/mention/lib/plugin.css';
import AddEvent from './AddEvent';

const mentionPlugin = createMentionPlugin({
    supportWhitespace:true
});

const {MentionSuggestions} = mentionPlugin;
const plugins = [mentionPlugin];


//const imageMaxSize = 5242880 // bytes
//const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
//const acceptedFileTypesVideo = 'video/mp4, video/3gp, video/ogg, video/flv, video/avi, video/wmv, video/mov'
//const acceptedFileTypesVideoArray = acceptedFileTypesVideo.split(",").map((item) => {return item.trim()})
//const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class CreatePost extends Component {

    constructor (props){
        super(props);
    
        this.state = {
            editorState: EditorState.createEmpty(),
            open: false,
            suggestions: [],              
            mentions: [],              
            text : "",
            communityID : this.props.community ? this.props.community : 0,
            defaultCommunityID : this.props.community ? Number(this.props.community) : 0,
            dragIsActive : false,
            images : [],
            imagesFiles : [],
            video : [],
            videosFiles : [],
            docx : [],
            docxFiles : [],
            type : 0,
            gif : null,
            gifSmall : null,
            link : null,
            uploadPercentage: 0,
            communityHasSelected : true,
            fileSelected : false,
            error : false,
            disablePost : false,
            isSharingGif : false,
            RoleSelect : {
                items : [{value : 0, label : "Sélectionner une communauté"}],
                selectedRole  : {value : 0, label : "Sélectionner une communauté"},
                selectedValue : {value : 0, label : "Sélectionner une communauté"},
            }
        }
         
        this.InputImage = React.createRef();
        this.InputVideo = React.createRef();
        this.InputFiles = React.createRef();
        this.shareIconBtn = React.createRef();
        this.shareEmojiBtn = React.createRef();
        this.txtVal = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.getTextFromTextEditor = this.getTextFromTextEditor.bind(this);
        this.hideSelectGitSectioon = this.hideSelectGitSectioon.bind(this);
    }
    getTextFromTextEditor = ()=>{
        const contentState =  this.state.editorState.getCurrentContent();
        const rocontentStateRow = convertToRaw(contentState);
        let textBlocks = rocontentStateRow.blocks
        let mentionObj = rocontentStateRow.entityMap
        let mentionArr = Object.values(mentionObj);
        let newText='';
        let MentionArrToSend=[];
        newText = this.getAllEditorText()
        console.log('newText',newText);
        if (mentionArr.length>0) {
            for (let index = 0; index < mentionArr.length; index++) {
                const element = mentionArr[index];
                if (element.type=="mention"){
                    MentionArrToSend.push(element.data.mention.email)
                    newText = newText.replace(element.data.mention.name,`<span class="tagueName" id="userId-${element.data.mention.id}" data-id="168">@${element.data.mention.name.split(' ')[0]}</span>`)
                }
            }
        }
        this.setState({
            text : newText,
            error : false
        }, () => {
           this.scrollToBottom()
        });

        var testUrl = newText.match(/(https?:\/\/[^\s]+\.[^\s]+)/),
        onlyUrl = testUrl && testUrl[1];
        if(onlyUrl){
            this.setState({
                link : onlyUrl,
                type: 'link'
            })
        }
        console.log('2=newText',newText);

        return {
            newText:newText,
            MentionArrToSend:MentionArrToSend.length>0 ? MentionArrToSend : null
        };
    }
    onChangeEditor = editorState => {
        this.setState({
          editorState,
        });
      };

    onSearchChange= ({value})=>{
        this.setState({
            suggestions : defaultSuggestionsFilter(value,this.state.mentions)
          });
    }
    onOpenChange = (_open)=>{
        if(this.state.communityID <= 0){
            this.setState({
                communityHasSelected : false
            })
            return;
        }
        let communityID = this.state.communityID
        let data = {
            'idgroup':communityID
        }
        axios.post(`${Global.API_URL}/api/tag`,data).then((res) => {
            if (res && res.data) {
               let newArr=[]
               for (let index = 0; index < res.data.length; index++) {
                   const element = res.data[index];
                   newArr.push({
                        link:  element.email,
                        avatar:  `${Global.API_URL}${element.image}`,
                        name:  `${element.prenom} ${element.nom}`,
                        id:  element.id,
                        email:  element.email,
                   })
               }
               this.setState({
                    open:_open,
                    suggestions: newArr, 
                    mentions: newArr, 
                });
            }
        }).catch((err) => {
            // console.log(err.message);
        });
        
        
    }
    resetFotm(){
        const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
        this.setState({
            isActive: false,
            showPollForm: false,
            showEventForm: false,
            text : "",
            dragIsActive : false,
            images : [],
            imagesFiles : [],
            video : [],
            videosFiles : [],
            docx : [],
            docxFiles : [],
            type : 0,
            gif : null,
            gifSmall : null,
            link : null,
            link : null, 
            disablePost : false, 
            uploadPercentage: 0,
            communityHasSelected : true,
            editorState:editorState
        })
        
    }

    scrollToBottom(){
        setTimeout(() => {
            animateScroll.scrollToBottom({
                containerId: "newPostTextArea",
                duration: 0
            });
        });
    }

    handleChange(ev){
        
        
        this.setState({
            text : ev.target.value,
            error : false
        }, () => {
            // this.setState({
            //     txtHeight: this.txtVal.current.clientHeight
            // 
           this.scrollToBottom()
        });
        
       
        var testUrl = ev.target.value.match(/(https?:\/\/[^\s]+\.[^\s]+)/),
        onlyUrl = testUrl && testUrl[1];


        if(onlyUrl){
            this.setState({
                link : onlyUrl,
                type: 'link'
            })
        }
 
        
    }

    SubmitPost(ev){
        ev.persist()
        ev.preventDefault();
        const tagueArry= this.getTextFromTextEditor()
        //MentionArrToSend
        //newText
        var formData = new FormData();
        if(this.state.communityID <= 0){
            this.setState({
                communityHasSelected : false
            })
            return;
        }
        if(!this.state.fileSelected && tagueArry.newText === ""){
            this.setState({
                error : true
            })
            return;
        }
        ev.target.setAttribute("disabled", true);
        this.setState({
            communityHasSelected : true,
            error : false
        })

        this.setState({
            disablePost : true,
        })
        formData.append('email', this.props.auth.user.username);
        formData.append('idgroup', this.state.communityID);
        formData.append('text', tagueArry.newText);
        formData.append('type', this.state.type);
        if (tagueArry.MentionArrToSend) {
            for(var $j = 0;$j < tagueArry.MentionArrToSend.length; $j++){
                formData.append('emailtager[]', tagueArry.MentionArrToSend[$j]);
            }
        }
        switch(this.state.type){
            case "images":
                for(var $j = 0;$j < this.state.imagesFiles.length; $j++){
                    formData.append('fichiers[]', this.state.imagesFiles[$j]);
                }
                break;
            case "video":
                for(var $j = 0;$j < this.state.videosFiles.length; $j++){
                    formData.append('fichiers', this.state.videosFiles[$j]);
                }
                break;
            case "document":
                for(var $j = 0;$j < this.state.docxFiles.length; $j++){
                    formData.append('fichiers[]', this.state.docxFiles[$j]);
                }
                break;
            
            case "docpicture":
                for(var $j = 0;$j < this.state.docxFiles.length; $j++){
                    formData.append('docs[]', this.state.docxFiles[$j]);
                }
                for(var $j = 0;$j < this.state.imagesFiles.length; $j++){
                    formData.append('picture[]', this.state.imagesFiles[$j]);
                }
                break;
            
            case "docvideo":
                for(var $j = 0;$j < this.state.docxFiles.length; $j++){
                    formData.append('docs[]', this.state.docxFiles[$j]);
                }
                for(var $j = 0;$j < this.state.videosFiles.length; $j++){
                    formData.append('video', this.state.videosFiles[$j]);
                }
                break;

            case "gif":
                formData.append('gif', this.state.gif);
                break;
            case "link":
                formData.append('link', this.state.link);
                break;
            
        }  

        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
        
                if( percent <= 100 ){
                    this.setState({ uploadPercentage: percent })
                }
            },
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axios
        .post(Global.API_URL+"/api/group/publication", formData, options)
        .then((res) => {
            ev.target.removeAttribute("disabled");
            this.props.onCreatePost(res); 
            this.resetFotm() 
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    handleSelectChange (e){

        if(e.value <= 0){
            this.setState({
                communityID : e.value,
                communityHasSelected : false
            })
        }else{
            this.setState({
                communityID : e.value,
                communityHasSelected : true
            })
        } 
    } 

    confirmReplaceVideoByPhotosMessage(){ 
        if( this.state.video.length > 0){
            if(window.confirm(`Vous ne pouvez pas publier des photos et des vidéos sur un même post. Si vous vous cliquez sur confirmer les videos seront supprimées`)){
                return true
            }else{ 
                return false
            }
        }
        return true
    }
    
    handleImageChange (e){
        //// prevent Message 
        if(!this.confirmReplaceVideoByPhotosMessage()) return false;
        


        var images;
        var files;

        if(this.state.type === "images" || this.state.type === "docpicture"){
            images = this.state.images;
            files = this.state.imagesFiles;
        }else{
            images = [];
            files = [];
        }

        for(var i = 0; i < e.target.files.length; i++){
            images.push(URL.createObjectURL(e.target.files[i]));
            files.push(e.target.files[i]);
        }

        this.setState({
            images: images,
            imagesFiles : files,
            video: [],
            videosFiles : [],
            gif : null,
            gifSmall : null,
            type : this.state.docxFiles.length > 0 ? 'docpicture' : 'images',
            fileSelected : true
        }, ()=>{
            console.log(this.state)
        })

        

        this.InputImage.current.value = null;

        this.scrollToBottom()
    }


    confirmReplacePhotosByVideoMessage(){  
        if( this.state.images.length > 0){
            if(window.confirm(`Vous ne pouvez pas publier des photos et des vidéos sur un même post. Si vous vous cliquez sur confirmer les photos seront supprimées`)){
                return true
            }else{ 
                return false
            }
        }
        return true
    }
    handleVideoChange(e){  

        //// prevent Message 
        if(!this.confirmReplacePhotosByVideoMessage()) return false; 
 

        var video = [];
        var files = [];
        for(var i = 0; i < e.target.files.length; i++){
            video.push(e.target.files[i].name);
            files.push(e.target.files[i]);
        }

        this.setState({
            video: video,
            videosFiles : files,
            images: [],
            imagesFiles : [],
            gif : null,
            gifSmall : null,
            type : this.state.docxFiles.length > 0 ? 'docvideo' : 'video',
            fileSelected : true
        })

        this.InputVideo.current.value = null;

        this.scrollToBottom()
    }
    handleDocxChange(e){
        var docx;
        var files;

        if(this.state.type === "document"){
            docx = this.state.docx;
            files = this.state.docxFiles;
        }else{
            docx = [];
            files = [];
        }

        for(var i = 0; i < e.target.files.length; i++){
            docx.push(e.target.files[i].name);
            files.push(e.target.files[i]);
        }

        this.setState({
            docx: docx,
            docxFiles : files,
            gif : null,
            gifSmall : null,
            type : this.state.imagesFiles.length > 0 ? 'docpicture' : this.state.videosFiles.length > 0 ? 'docvideo' : 'document',
            fileSelected : true
        })
        
        this.InputImage.current.value = null;

        this.scrollToBottom()
    }
    handleGifChange (gif){
        this.setState({
            gif : gif.original.url,
            gifSmall : gif.preview_gif.url,
            docx: [],
            files : [],
            images : [],
            video : [],
            type : 'gif',
            fileSelected : true,
            isSharingGif : false
        })
         
        this.shareIconBtn.current.classList.remove('on')

        this.scrollToBottom()
    }
    handleShareIcon(ev){
        ev.preventDefault();
        this.setState({
            isSharingGif : true
        })
        this.shareIconBtn.current.classList.toggle('on');

        this.scrollToBottom()
    }
    hideSelectGitSectioon(){
        this.setState({
            isSharingGif : false
        })
        this.shareIconBtn.current.classList.toggle('on');
        this.scrollToBottom()
    }
    handleShowPollForm(ev){
        ev.preventDefault();
        this.setState({
            showPollForm : true
        }) 
    }
    handleShowEventForm(ev){
        ev.preventDefault();
        this.setState({
            showEventForm : true
        }) 
    }

    removeImage(ev){
        var target = ev.target.getAttribute('data-target');
        var images = this.state.images;
        var files = this.state.imagesFiles;
        images.splice(target, 1);
        files.splice(target, 1);

        this.setState({
            images : images,
            imagesFiles : files
        })
    }
    removeVideo(ev){
        var target = ev.target.getAttribute('data-target');
        var video = this.state.video;
        var files = this.state.videosFiles;
        video.splice(target, 1);
        files.splice(target, 1);

        this.setState({
            video : video,
            videosFiles : files
        })
    }
    removeDoc(ev){
        var target = ev.target.getAttribute('data-target');
        var docx = this.state.docx;
        var files = this.state.docxFiles;
        docx.splice(target, 1);
        files.splice(target, 1);

        this.setState({
            docx : docx,
            docxFiles : files
        })
    }
    removeGif (ev){
        ev.preventDefault();
        this.setState({
            gif : null
        })
    }
    handleClickOutside(event) {

        // if( this.state.RoleSelect.items.length <= 1 ) return
        // if( this.state.showEventForm ) return
        
        // if(this.state.isSharingGif){
        //     this.shareIconBtn.current.classList.remove('on');
        //     this.setState({
        //         isSharingGif : false
        //     })
        // }
        // if(this.state.isSharingEmoji){
        //     this.shareEmojiBtn.current.classList.remove('on');
        //     this.setState({
        //         isSharingEmoji : false
        //     })
        // }

        // if (this.shareIconBtn && !this.shareIconBtn.current.contains(event.target) && document.querySelector('.share-icon .icons-list .sc-bdVaJa') && !document.querySelector('.share-icon .icons-list .sc-bdVaJa')?.contains(event.target)) {
        //     this.shareIconBtn.current.classList.remove('on');
        //     this.setState({
        //         isSharingGif : false
        //     })
        // }
        // if (this.shareEmojiBtn && !this.shareEmojiBtn.current.contains(event.target) && document.querySelector('.share-icon .emoji-list .emoji-picker-react') && !document.querySelector('.share-icon .emoji-list .emoji-picker-react')?.contains(event.target)) {
        //     this.shareEmojiBtn.current.classList.remove('on');
        //     this.setState({
        //         isSharingEmoji : false
        //     })
        // }
    }
    handleEmoji (ev){
        ev.preventDefault();
        this.setState({
            isSharingEmoji: true
        });
        ev.target.classList.toggle('on');
        
    }
    insertCharacter(characterToInsert, editorState) {
        const currentContent = editorState.getCurrentContent(),
        currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            characterToInsert
        );

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

        return  EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    }
    getAllEditorText(){
        const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        return value
    }
    onEmojiClick = (event, emojiObject) => {
        this.setState({
            text: this.state.text + emojiObject.emoji,
            isSharingEmoji: false
        })
        // const outputText = this.state.editorState.getPlainText()
      

        let emoji =  emojiObject.emoji;
        this.setState({editorState: this.insertCharacter(emoji, this.state.editorState)});


        
    
    };
    componentDidMount() {
        document.addEventListener('mouseup', this.handleClickOutside);

        axios
        .post(Global.API_URL + "/murgroupid", { 'email': this.props.auth.user.username })
        .then((res) => {
            
            let items = [
                {value : 0, label : "Sélectionner une communauté"}
            ];
            for(let $i = 0; $i < res.data.length; $i++ ){
                items.push({value: res.data[$i].groups.id, label : res.data[$i].groups.titre })
            }
            this.setState({
                RoleSelect : {
                    items : items,
                    selectedRole  : {value : 0, label : "Sélectionner une communauté"},
                    selectedValue : {value : 0, label : "Sélectionner une communauté"},
                }
            });
        }).catch((err) => {
            // console.log(err.message);
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            communityID : nextProps.community ? nextProps.community : 0,
            defaultCommunityID : nextProps.community ? Number(nextProps.community) : 0,
        })
    } 
    
    render() {
        const { RoleSelect } = this.state;

        if( RoleSelect.items.length <= 1 ) return '' 

        if( this.state.showEventForm ){
            return(
                <AddEvent
                RoleSelect={RoleSelect}
                communityID={this.state.defaultCommunityID}
                auth={this.props.auth}
                resetFotm={()=>{console.log("click");this.resetFotm()}}
                hideEventForm={()=>{this.setState({showEventForm: false})}}
                onSubmit={(res)=>{
                    this.props.onCreatePost(res); 
                    this.resetFotm() 
                }}
                />
            )
        }
        else if( this.state.showPollForm ){
            return(
                <AddPoll
                RoleSelect={RoleSelect}
                communityID={this.state.defaultCommunityID}
                auth={this.props.auth}
                resetFotm={()=>{console.log("click");this.resetFotm()}}
                hidePollForm={()=>{this.setState({showPollForm: false})}}
                onSubmit={(res)=>{
                    this.props.onCreatePost(res); 
                    this.resetFotm() 
                }}
                />
            )
        }
        else return (
            <div 
                onFocus={() => this.setState({isActive : true})}
                className={"create-post " + 
                    (this.state.disablePost ? " disable " : "") + 
                    ((this.state.defaultCommunityID === 0 || !this.state.defaultCommunityID) ? "" : " create-community-post ") + 
                    (this.state.isActive ? " active " : "")}>
                <span className="trs-color"></span>
                <span className="cancel-post" onClick={()=>{this.resetFotm()}}>
                    <i className="fa fa-close"></i>
                </span>
                {(this.state.defaultCommunityID === 0 || !this.state.defaultCommunityID) ? 
                    <div className="title-create-post">
                        <div className="jc-space-b align-center f-wrap-xs">
                            <div className=""> 
                                    <InputField
                                        type="select" 
                                        id={"_community"}
                                        name="community" 
                                        label="" 
                                        fixedLabel="true" 
                                        placeholder=""
                                        items={RoleSelect.items}
                                        classInput="" 
                                        classContainer={!this.state.communityHasSelected ? "error" : "" } 
                                        value={RoleSelect.selectedRole} 
                                        selectedValue={RoleSelect.selectedValue} 
                                        default={RoleSelect.selectedRole}
                                        oninput={this.handleSelectChange.bind(this)} 
                                        errorMsg="" /> 
                            </div>
                        </div>
                    </div>
                    : 

                    <input 
                        type="hidden"
                        id={"_community"}
                        name="community"
                        value={this.state.defaultCommunityID}
                    /> 

                    }
                <div className="content-post" id="newPostTextArea"> 
                    <div className={"textarea  postNormal" + (this.state.error ? "error" : "")}>
                        <div className="wrapper">
                            <span ref={this.txtVal} className="txtVal creatPostRf">
                            {this.state.text && this.state.text.split("\n").map(function(item, idx) {
                                return (
                                    <span key={"_txt"+idx}>
                                    {item}
                                    <br/>
                                    </span>
                                    )
                                })
                            }
                            </span>
                            <Editor
                                editorState={this.state.editorState}
                                onChange={this.onChangeEditor.bind(this)}
                                plugins={plugins}
                                placeholder={"Ecrire une publication"} 
                                value={this.state.text} 
                                onFocus={() => this.setState({isActive : true})}
                                ClassName="PostEditor"
                            />
                            <MentionSuggestions  
                                open={this.state.open} 
                                onOpenChange={this.onOpenChange} 
                                onSearchChange={this.onSearchChange.bind(this)} 
                                suggestions={this.state.suggestions} 
                            />
                            {/* <textarea 
                                placeholder="Ecrire une publication" 
                                onChange={this.handleChange.bind(this)} 
                                name="text-post" 
                                id="text-post" 
                                className="text-post " 
                                value={this.state.text} 
                                // style={{height: (this.state.txtHeight + 24) + 'px'}}
                                ></textarea> */}
                            <span className="figure" style={{backgroundImage: 'url('+ Global.API_URL  + this.props.auth.user.profil +')' }}>
                                { this.props.auth.user.profil === '0' ? this.props.auth.user.name.charAt(0) + this.props.auth.user.lastname.charAt(0) : ''} 
                            </span>
                        </div>
                    </div>
                    {this.state.link !== null &&
                        <div className="link-preview">         
                            {/* <CustomLinkPreview link={this.state.link} loader={true}/> */}
                        </div>
                    }

                    {this.state.docx.length > 0 && 
                        <div className="UploadedFilesName">
                            {this.state.docx.map((file, index) => (
                                <span key={"docx-"+index}>
                                    {
                                        configs.getExtention(file)=="m4a"?
                                        <span className="remove" ><i  data-target={index} onClick={this.removeDoc.bind(this)}>X</i>&nbsp; <b className="fa fa-volume-up"></b> &nbsp;&nbsp;{file}</span>
                                        :
                                        <span className="remove" ><i  data-target={index} onClick={this.removeDoc.bind(this)}>X</i>&nbsp; <b className="fa fa-file-o"></b> &nbsp;&nbsp;{file}</span>
                                    }
                                </span>
                                ))}
                        </div>
                    }

                    {this.state.images.length > 0 && 
                        <div className="UploadedImagesDisplay">
                            {this.state.images.map((image, index) => (
                                <span key={"image-"+index} style={{backgroundImage : 'url(' + image + ')'}} >
                                    <span className="remove" ><i  data-target={index} onClick={this.removeImage.bind(this)}>X</i></span>
                                </span>
                                ))}
                        </div>
                    }
                    {this.state.gif && 
                        <div className="UploadedGifDisplay">
                            <span>
                                <span className="remove" >
                                    <i onClick={this.removeGif.bind(this)}>X</i>
                                    <img src={this.state.gifSmall} className="gif" alt="" />
                                </span>
                            </span>
                        </div>
                    }
                    {this.state.video.length > 0 && 
                        <div className="UploadedVideoName">
                            {this.state.video.map((vid, index) => (
                                <span key={"vid-"+index}>
                                    <span className="remove" ><i  data-target={index} onClick={this.removeVideo.bind(this)}>X</i>&nbsp; <b className="fa fa-file-video-o"></b> &nbsp;&nbsp;{vid}</span>
                                </span>
                                ))}
                        </div>
                    } 
                    {this.state.uploadPercentage !== 0 && 
                        <div className="loaderProgress">
                            {
                                this.state.uploadPercentage < 100 
                                ?
                                    <div className="progressBar">
                                        <span style={{width: this.state.uploadPercentage + "%"}}></span>
                                    </div>
                                :
                                <span><span className="fa fa-spinner fa-spin"></span> Votre publication est en cours de traitement. Veuillez patienter</span>
                            }
                            
                            
                        </div>
                    } 
                    </div><div className="ft-create-post">
                        <ul>
                            <li>
                                <a href="#">
                                    <label htmlFor="uploadImages">
                                        <span className="fa fa-picture-o"></span>
                                        <input 
                                            ref={this.InputImage}
                                            type="file" 
                                            name="uploadImages[]" 
                                            multiple 
                                            id="uploadImages" 
                                            accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif" 
                                            onChange={this.handleImageChange.bind(this)} />
                                        Photos
                                    </label>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <label htmlFor="uploadVideo">
                                        <span className="fa fa-video-camera"></span> 
                                        <input 
                                            ref={this.InputVideo}
                                            type="file" 
                                            name="uploadVideo" 
                                            id="uploadVideo" 
                                            accept="video/mp4, video/3gp, video/ogg, video/flv, video/avi, video/wmv, video/quicktime" 
                                            onChange={this.handleVideoChange.bind(this)} />
                                        Vidéo
                                    </label>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <label htmlFor="uploadFiles">
                                        <span className="fa fa-files-o"></span> 
                                        <input 
                                            ref={this.InputFiles}
                                            type="file" 
                                            name="uploadFiles" 
                                            multiple 
                                            id="uploadFiles" 
                                            accept=".wav,.m4a,.mp3,.aac,.m4b,.opus,.ppt,.pptx,.xls,.xlsx,.csv,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                                            onChange={this.handleDocxChange.bind(this)} />
                                        Fichiers
                                    </label>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e)=>{this.handleShowPollForm(e)}}>
                                    <label>
                                        <span className="fa fa-bar-chart"></span>  
                                        Sondage
                                    </label>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e)=>{this.handleShowEventForm(e)}}>
                                    <label>
                                        <span className="fa fa-calendar"></span>  
                                        Evenement
                                    </label>
                                </a>
                            </li>
                            {/* <li><Link to="/#"><span className="fa fa-calendar"></span> Créer un évènement</Link></li> */}
                            <li>
                                <Link to="/#" onClick={(e)=>{e.preventDefault()}}  className="share-icon">
                                    <span ref={this.shareIconBtn} onClick={this.handleShareIcon.bind(this)} className=""><span className="custom-icon custom-icon-gif"></span></span>

                                    {/* {this.state.isSharingGif &&
                                        <div className="icons-list">
                                            <Picker placeholder="Rechercher" apiKey="u3PkS4gInlk051bpDTIbPo7Ga9H6ZDc3" onSelected={this.handleGifChange.bind(this)} style={{position : 'fixed'}} />
                                        </div>
                                    } */}
                                </Link>
                            </li>
                            <li>
                                <Link to="/#" onClick={(e)=>{e.preventDefault()}}  className="share-icon">
                                    <span ref={this.shareEmojiBtn} onClick={this.handleEmoji.bind(this)} className="fa fa-smile-o"></span>

                                    {this.state.isSharingEmoji &&
                                        <div className="emoji-list">
                                            <PickerEmoji onEmojiClick={this.onEmojiClick.bind(this)} style={{position : 'fixed'}} />
                                        </div>
                                    }
                                </Link>
                            </li>
                            <li>
                                <button onClick={this.SubmitPost.bind(this)} className="button"><span>Publier</span></button>
                            </li>
                        </ul>
                    </div>

                    {this.state.isSharingGif &&
                        <div className="icons-list  gifSelect" >
                            <div className="backgrongFixd" onClick={this.hideSelectGitSectioon.bind(this)}></div>
                            <div className="giffCnt" onClick={(e)=>{e.preventDefault()}} >
                                <Picker placeholder="Rechercher" apiKey="u3PkS4gInlk051bpDTIbPo7Ga9H6ZDc3" onSelected={this.handleGifChange.bind(this)} style={{position : 'fixed'}} />
                            </div>
                        </div>
                    }
                        {/* {this.state.isSharingEmoji &&
                            <div className="emoji-list">
                                <PickerEmoji onEmojiClick={this.onEmojiClick} style={{position : 'fixed'}} />
                            </div>
                        } */}
                
            </div>
        )
    }
}

CreatePost.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {} )(CreatePost);