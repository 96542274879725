import React, { useEffect, useState } from 'react'  
import Global from '../../../../inc/Global'
import Axios from 'axios';  

//components
import {Tabs, Tab} from 'react-bootstrap'; 
import BeatLoader from "react-spinners/BeatLoader";
import InputField from '../../../InputField';

// Google chart
import Chart from 'react-google-charts'


const Reactions = (props)=>{  

    const [stats, setStats] = useState([])
    const [graphStats, setGraphStats] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const [dateStart, setDateStart] = useState(new Date(new Date().setDate(new Date().getDate() - 30)))
    const [dateEnd, setDateEnd] = useState(new Date()) 
    const [chartHeight, setChartHeight] = useState(100)

    useEffect(() => { 
        getStats()
    }, [dateStart, dateEnd]); 

    const getStats = ()=>{
        setLoaded(false)
        let data = new FormData()

        data.append('timestart', dateStart.toISOString())
        data.append('timesend', dateEnd.toISOString())

        Axios.post(Global.API_URL + "/api/admin/stataction", data).then((res) => {
            if( res.data ){  
                let result = []
                res.data.sort((a,b)=> {return b.nbpost - a.nbpost}).map(item=>{
                    if( item.nbpost > 0 &&  item.nbcommentaire > 0 && item.nblike > 0 ){
                        result.push([
                            `\n${item.user?.profile?.prenom}\n${item.user?.profile?.nom}`,
                            item.nbpost,
                            item.nbcommentaire,
                            item.nblike,
                        ])
                    } 
                }) 
                
                if( result.length > 1 ){
                    setChartHeight(100 * result.length - 1) 
                    setGraphStats([['', 'Publications', 'Commentaires', "J'aimes"], ...result])
                }

                setStats(res.data)
            }
            setLoaded(true)
        })
    }  

    return(
        <div className="stats stats-reactions"> 
            <h2>Stats Utilisateurs</h2>
            <p>Nombre de posts, commentaires, likes, vues...</p>

            <form>
                <div className="d-flex filter-by-date">
                    <div>
                        <InputField 
                            label="Date début"
                            type="date" 
                            popperPlacement="top-start"
                            id="dateStart" 
                            name="dateStart"  
                            fixedLabel="false" 
                            classInput=""  
                            classContainer={""} 
                            value={dateStart} 
                            minDate={new Date()}
                            oninput={(date)=>{setDateStart(date)}}  />
                    </div>
                    <div> 
                        <InputField 
                            label="Date de fin"
                            type="date" 
                            popperPlacement="top-start"
                            id="dateEnd" 
                            name="dateEnd"  
                            fixedLabel="false" 
                            classInput=""  
                            classContainer={""} 
                            value={dateEnd} 
                            minDate={new Date()}
                            oninput={(date)=>{setDateEnd(date)}}  />
                    </div>
                </div>
            </form>

            {
                !isLoaded &&
                <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
            }
            
            {
                isLoaded &&
                <> 

                    <Tabs defaultActiveKey="tab-1" id="stats-reactions">
                        <Tab eventKey="tab-1" title="Graphique"> 
                            {
                                graphStats.length > 0 &&
                                <div className="google-chart">
                                    <Chart 
                                        width={'100%'}
                                        height={`${chartHeight}px`}
                                        chartType="Bar"
                                        loader={<div>Loading Chart</div>}
                                        data={graphStats}
                                        options={{
                                            // Material design options
                                            chart: {
                                            title: '',
                                            subtitle: '', 
                                            },
                                            bars: 'horizontal',
                                        }} 
                                    />
                                </div>
                            }   
                        </Tab>
                        <Tab eventKey="tab-2" title="Données"> 
                            <table>
                                <thead>
                                    <tr> 
                                        <th>Nom et Prénom</th> 
                                        <th>Nbr publication</th>
                                        <th>Nbr commentaires</th>
                                        <th>Nbr likes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stats.sort((a,b)=>{return new Date(b.lastconnexion) - new Date(a.lastconnexion);}).map(item=>(
                                        <tr> 
                                            <td>
                                                {item.user?.profile?.prenom} {item.user?.profile?.nom}<br />
                                                <small>{item.user.email}</small>
                                            </td>
                                            <td>{item.nbpost}</td> 
                                            <td>{item.nbcommentaire}</td>
                                            <td>{item.nblike}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> 
                        </Tab>
                    </Tabs> 
                </>
            }
        </div>
    )
}

export default Reactions