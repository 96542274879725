import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Global from '../../inc/Global';
import axios from 'axios'
import SHA1 from 'sha1'

class Settings extends Component {
    constructor (props){
        super(props);

        if(typeof this.props.location.state === "undefined" ){
            return;
        }else if(typeof this.props.location.state.id === "undefined"){
            return;
        }

        const params = this.props.location.state;

        this.state = {
            id : params.id,
            logo : params.logo,
            cover : params.cover,
            subject : params.subject,
            type : params.type,
            title : params.title,
            nbuser : false,
            invitation : params.invitation,
            email : params.email,
            nbinvitation : 0,
            color : params.color,
            delete : false,
            isAdmin : true
        }
        // console.log(params)
    }
    componentDidMount(){
        if(!this.state){
            return;
        }
        const data = {
            id : this.state.id
        };
        
        axios
        .post(Global.API_URL+"/api/getgroupspecifique?c="+SHA1(new Date()), data)
        .then((res) => {
            this.setState({
                nbuser : res.data[0].nbuser,
                nbinvitation : res.data[0].nbinvitation
            })
        
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }
    cancel (e){
        e.preventDefault();
        this.setState({
            delete : false
        });
    }

    handleDelete (ev) {
        ev.preventDefault();
        this.setState({
            delete : true
        });
    }
    confirmDeletion (ev) {
        ev.preventDefault();        
        
        axios
        .delete(Global.API_URL+"/api/deletegroup/"+this.state.id)
        .then((res) => {
            this.props.history.push('/communautes')
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            
            this.setState({
                delete : false
            });
        });
    }
    render() {
        if(typeof this.props.location.state === "undefined" ){
            this.props.history.push('/communautes')
            return "";
        }else if(typeof this.props.location.state.id === "undefined"){
            this.props.history.push('/communautes')
            return "";
        }
        return (
            <section className="content">
                <div className="wrap">
                    <div className="community-settings">

                        <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3 className="h3"> 
                                    <center>
                                        <Link to={`/communaute/${this.state.id}`}>
                                            <span className="fa fa-long-arrow-left"></span>
                                        </Link> 
                                        Paramètres de la communauté
                                    </center>
                                </h3>  
                            </div>
                        </div> 
                        <div className="settings-menu p-10 bg-white">
                             

                            <div className="_mn-items">
                                <div className="item">
                                    <div className="">
                                        <b>
                                            <Link to={{
                                                pathname : `/communaute/${this.state.id}/users`,
                                                state : {id : this.state.id}
                                            }}>
                                                Membres de la communauté 
                                                {
                                                    this.state.nbuser &&
                                                    <span> ({this.state.nbuser})</span>
                                                }
                                            </Link>
                                        </b>
                                        
                                        <span className="icon-arrow"></span>
                                    </div> 
                                </div>
                                <div className="_submn-items">
                                    {this.state.nbinvitation !== 0 &&
                                        <div className="item">
                                            <div className="">
                                                <b><Link to={{
                                                    pathname : `/communaute/${this.state.id}/invitations`,
                                                    state : {id : this.state.id}
                                                }}>Invitation en attente</Link></b>
                                                
                                                <span className="_nb-members">{this.state.nbinvitation}</span>
                                                <span className="icon-arrow"></span>
                                            </div>
                                        </div>
                                    }
                                    <div className="item">
                                        <div className="">
                                            <b><Link to={{
                                                    pathname : `/communaute/${this.state.id}/inviter-membre`,
                                                    state : {id : this.state.id}
                                                }}>Inviter des membres</Link></b>

                                            <span className="icon-arrow"></span>
                                        </div>
                                    </div>
                                        <div className="item">
                                            <div className="">
                                                <b><Link to={
                                                    {
                                                        pathname : `/communaute/${this.state.id}/inviter-extern-membre`,
                                                        state : {id : this.state.id}
                                                    }
                                                }>Inviter des externes</Link></b>

                                                <span className="icon-arrow"></span>
                                            </div>
                                        </div>
                                    </div> 
                                <div className="item">
                                    <div className="">
                                        <b><Link to={{
                                            pathname : `/communaute/${this.state.id}/modifier`,
                                            state : {
                                                id : this.state.id,
                                                title : this.state.title,
                                                subject : this.state.subject,
                                                type : this.state.type,
                                                color : this.state.color,
                                                logo : this.state.logo,
                                                cover : this.state.cover
                                            }
                                        }}>Personnalisation</Link></b>

                                        <span className="icon-arrow"></span>
                                    </div>
                                </div>
                                {/* <div className="item">
                                    <div className="">
                                        <b><Link to="">Gestion des modules</Link></b><br />
                                        <span>Lorem ipsum dolor sit amet consectetur.</span>

                                        <span className="icon-arrow"></span>
                                    </div>
                                </div> */}
                                <div className="item">
                                    <div className="">
                                        <b><Link to="" onClick={this.handleDelete.bind(this)}>Supprimer la communauté</Link></b>

                                        <span className="icon-arrow"></span>
                                    </div>
                                </div>
                            </div>

                            {this.state.delete && 
                                <div className="_modal confirmation" id="DeleteCom">
                                    <div className="_overlay"></div>
                                    <div className="_modal-wrap">
                                        <div className="_modal-content">
                                            <div className="_modal-header">
                                                <h2>Êtes-vous sûr de vouloir supprimer cette communauté&nbsp;?</h2>
                                            </div>
                                            <div className="_modal-body">
                                            <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                                <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                                <button onClick={this.confirmDeletion.bind(this)} ref={this.DeleteBtn} className="button lg">
                                                    <span className="fa fa-spin fa-spinner"></span> Supprimer
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Settings;