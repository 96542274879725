import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/img/white-logo.png'
import Global from '../../inc/Global';
import axios from 'axios'
import jwt_decode from "jwt-decode";
import SHA1 from 'sha1'

class Congratulation extends Component {
    constructor(props){
        super(props);

        this.buttonHereWeGo = React.createRef(null);
    }
    handleFinishButton(ev){
        ev.preventDefault();
        let btn = this.buttonHereWeGo.current;

        btn.classList.add('on');
        localStorage.removeItem('jwtUd');
        const decoded = jwt_decode(localStorage.jwtToken);
        axios
        .get(Global.API_URL + "/api/checkprofil/" + SHA1(new Date()))
        .then((response) => {
            // console.log(decoded);
            decoded['profil'] = response.data.image;
            decoded['name'] = response.data.prenom;
            decoded['lastname'] = response.data.nom;
            decoded['user_id'] = response.data.user_id;
            decoded['create_communities'] = response.data.casecoche == '1' ? true : false;
            localStorage.setItem("jwtUd", JSON.stringify(decoded) )
            //dispatch(setCurrentUser(decoded));
            this.props.history.push('/'); 

            btn.classList.remove('on');
            window.location.reload();
        }).catch((err) => {
            // console.log(err.message);
        });
    }
    render() {
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">

                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="_cnu-tabs">
                                            <div className="_cnu-logo">
                                                <img src={Global.APP_LOGO} alt="" />
                                            </div>
                                            <h3>Compléter mon profil</h3>
                                            <div className="bg-white-transparent p-10 brs-10">
                                                <div className="tab _mp-congratulations d-flex align-center jc-center">
                                                <div className="text-center">
                                                        <div className="aplaude">
                                                            <img width="100" src="img/aplaude.png" alt="" />
                                                        </div>
                                                        <h2>Félicitations</h2>
        
                                                        <div className="p">
                                                            <p>Vous pouvez désormais accéder à tout l'univers {Global.APP_NAME}</p>
                                                        </div>
                                                </div>
                                                </div>
                                                <div className="_cnu-footer-tabs footer-fixed m-t-10">
                                                    <Link ref={this.buttonHereWeGo} onClick={this.handleFinishButton.bind(this)} to="/" className="button lg full">
                                                        C'EST PARTI!  <span className="fa fa-spin fa-spinner"></span> 
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Congratulation;