import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import InvitationItem from './InvitationItem'
import axios from 'axios'
import Global from '../../inc/Global.js'
import debounce from "lodash.debounce";
import SHA1 from 'sha1'
import configs from '../../inc/helpers'

class Invitations extends Component {
    constructor(props){
        super(props)
        
        if(typeof this.props.location.state === "undefined" ){
            return;
        }else if(typeof this.props.location.state.id === "undefined"){
            return;
        }
        
        const params = this.props.location.state;

        this.state = {
            groupId : params.id,
            invitations : [],
            AllInvitations : [],
            limit : 10 ,
            step : 10,
            loaded : false,
            allLoaded : false,
            InvitationRejetedId : null
        }

        this.LoadMore = React.createRef();
        this.RefuseBtn = React.createRef();
        
        window.onscroll = debounce(() => {
            
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
                this.setState({
                    limit : this.state.limit + this.state.step
                })
                if(this.state.limit >= this.state.users.length){
                    this.setState({
                        allLoaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false
                    })
                }
            }
        }, 100);
    }
    
    componentDidMount(){

        // console.log(this.state.groupId)
        axios
        .post(Global.API_URL+"/invitattente?c="+SHA1(new Date()), {id : this.state.groupId})
        .then((res) => {
            
            var invitations = [];
            res.data.forEach(function(item, key) {
                
                invitations.push({
                    ID : item.user.id,
                    Email : item.user.email,
                    Name : item.user.profile ? item.user.profile.nom : "",
                    LastName : item.user.profile ? item.user.profile.prenom : "",
                    Photo : item.user.images ? item.user.images.image : "",
                    Job : item.user.profile ? item.user.profile.fonction : "",
                    PhoneNum : item.user.profile ? item.user.profile.telephone : ""
                });

            });
            this.setState({
                invitations : invitations,
                AllInvitations : invitations,
                loaded : true
            })
            // console.log(invitations)
            if(this.state.limit >= invitations.length){
                this.setState({
                    allLoaded : true
                })
            }else{
                this.setState({
                    allLoaded : false
                })
            }
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }
  
    handleSearchInput= async (event) => {
        const invitations = this.state.AllInvitations.filter(item =>
            (item.Name && item.Name.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.LastName && item.LastName.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.Job && item.Job.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.Email && item.Email.toLowerCase().includes(event.target.value.toLowerCase()))
        );
        //await this.setState({ invitations });
        await this.setState({
            limit : 10,
            invitations : invitations
        })

        if(this.state.limit >= this.state.invitations.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    confirmRefus (e){
        e.preventDefault();
        var btn = this.RefuseBtn;
        btn.current.classList.add('on');
        
        
        axios
        .post(Global.API_URL+"/api/userremovegroup", {id : this.state.groupId, iduser : this.state.userToReject})
        .then((res) => {
            btn.current.classList.remove('on');

            var invitations = [...this.state.invitations]; 
            var AllInvitations = [...this.state.AllInvitations];
    
            var currentUser = configs.getIndexWidthProperty(invitations, "ID", this.state.groupId);
            var currentAllUser = configs.getIndexWidthProperty(invitations, "ID", this.state.groupId);

            // invitations.forEach(function(el, ind){

            //     if(el.ID === parseInt(this.state.userToReject)){
            //         currentUser = ind;
            //     }
            // }.bind(this));

            // AllInvitations.forEach(function(el, ind){
    
            //     if(el.ID === parseInt(this.state.userToReject)){
            //         currentAllUser = ind; 
            //     }
            // }.bind(this));

            invitations.splice(currentUser, 1)
            AllInvitations.splice(currentAllUser, 1) 
            
            this.setState({
                userToReject : null,
                invitations : invitations,
                AllInvitations : AllInvitations
            });
            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            btn.current.classList.remove('on');
            this.setState({
                itemToDelete : null
            });
        });
        
        
    }
    handleReject (e) {
        e.preventDefault();
        this.setState({
            userToReject : e.target.getAttribute('id')
        });
    }
    cancel (e){
        e.preventDefault();
        this.setState({
            userToReject : null
        });
    }
    handleRoleChange = (ev) => {

    }
    handleAccept = (ev) => {
        ev.preventDefault();
        var targetid = ev.target.getAttribute('data-id');
        var targetemail = ev.target.getAttribute('data-email');
        var role = document.querySelector('#_role'+targetid+ ' [name="role"]').value;

        var token
        if(localStorage.jwtToken){
            token = localStorage.jwtToken;
        }else{
            token = "";
        }
        const data = {
            id_user : targetid,
            email : targetemail,
            id : this.state.groupId,
            role : role,
            invitation : 1,
            token : "Bearer " + token
        };

        axios
        .post(Global.API_URL+"/invitaccep", data)
        .then((res) => {
            var invitations = [...this.state.invitations]; 
            var AllInvitations = [...this.state.AllInvitations];
    
            var currentInv = configs.getIndexWidthProperty(invitations, "ID", targetid);
            var currentAllInv = configs.getIndexWidthProperty(AllInvitations, "ID", targetid);
            
            // console.log(targetid, currentInv);
            
            // invitations.forEach(function(el, ind){

            //     if(el.ID === parseInt(targetid)){
            //         currentInv = ind;
            //     }
            // }.bind(this));

            // AllInvitations.forEach(function(el, ind){
    
            //     if(el.ID === parseInt(targetid)){
            //         currentAllInv = ind; 
            //     }
            // }.bind(this));

            invitations.splice(currentInv, 1)
            AllInvitations.splice(currentAllInv, 1)
            
            this.setState({
                invitations : invitations,
                AllInvitations : AllInvitations
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    render() {
        if(typeof this.props.location.state === "undefined" ){
            this.props.history.push(`/communaute/${this.state.groupid}/parametres`)
            return "";
        }else if(typeof this.props.location.state.id === "undefined"){
            this.props.history.push(`/communaute/${this.state.groupid}/parametres`)
            return "";
        }

        return (
            <section className="content">
                <div className="wrap">
                    <div className="header-list">
                        <div className="d-flex jc-space-b align-center">
                            <h3 className="h3"> 
                                <center>
                                    <Link to="/admin/utilisateur/ajouter">
                                        <span className="fa fa-long-arrow-left"></span>
                                    </Link> 
                                    Liste utilisateurss
                                </center>
                            </h3>  
                        </div>
                    </div> 
                    <div className="users-list"> 

                        <div className="_srs-users">
                            <input type="search" name="s" onChange={this.handleSearchInput} placeholder="Rechercher un utilisateur"/>
                            <Link to="" className="button">Annuler</Link>
                        </div>
                        
                        {this.state.userToReject && 
                            <div className="_modal" id="CropImageModal">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Voulez-vous vraiment refuser cette invitation&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                        <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                            <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                            <button onClick={this.confirmRefus.bind(this)} ref={this.RefuseBtn} className="button lg">
                                                <span className="fa fa-spin fa-spinner"></span> Refuser
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="_ursl">
                            <ul>
                            {this.state.invitations.slice(0, this.state.limit).map((invitation, i) =>
                                <InvitationItem 
                                    key={invitation.ID}
                                    ID={invitation.ID}
                                    Name={invitation.Name}
                                    LastName={invitation.LastName}
                                    Photo={invitation.Photo}
                                    Job={invitation.Job}
                                    PhoneNum={invitation.PhoneNum}
                                    Email={invitation.Email}
                                    handleReject={this.handleReject.bind(this)} 
                                    RoleSelect={{
                                        items : [
                                            { value: 'ROLE_ADMIN', label: 'Administrateur' },
                                            { value: 'ROLE_MODERATEUR', label: 'Rédacteur'}
                                        ],
                                        selectedRole : { value: 'ROLE_MODERATEUR', label: 'Rédacteur' },
                                        selectedValue : { value: 'ROLE_MODERATEUR', label: 'Rédacteur' },
                                        handleSelectChange : this.handleRoleChange,
                                    }}
                                    handleAccept={this.handleAccept}
                                    />
                            )}
                            </ul>
                        </div>

                        <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de données</span>
                                :
                                <span className="fa fa-spinner fa-spin"></span>
                            }

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Invitations;