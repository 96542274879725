import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import configs from '../../inc/helpers'
import classnames from "classnames";
import InputField from '../InputField.js'
import axios from 'axios'
import Global from '../../inc/Global'
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authentication";
import SHA1 from 'sha1'
import Select from 'react-select';

class FinishProfil extends Component {
    constructor(props) {
        super(props);
        const email = jwt_decode(localStorage.jwtToken).username;
        this.state = {
            email: email,
            civility: "M.",
            civilitySelect: {value: "M.", label : "Civilité"},
            name: "",
            lastname: "",
            job: "",
            phone: "",
            day: "",
            month: "",
            year: "",
            country: "France",
            preference: "Français",
            description: "",
            errors: {},
            Error : "",
            HasNotLoggedIn: false,
            dateIsCorrect : "",
        };

        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleInputChange (e){
        console.log(e)
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    handleSelectChange (civility){
        
        this.setState({ civility : civility.value, civilitySelect : civility });
        // selectedOption can be null when the `x` (close) button is clicked
    }
    async handleDateChange (selectedDate){
        
        const date = new Date(selectedDate);

        await this.setState({ 
            day : date.getDate(),
            month : (date.getMonth() + 1),
            year : date.getFullYear(),
            dateIsCorrect : selectedDate ? "dateiscorrect" : "" 
        });
        
        
        // selectedOption can be null when the `x` (close) button is clicked
    }
    handleSubmit (e){
        e.preventDefault();
        var Valid = true;
        var errors = {
            civility : false,
            name : false,
            lastname : false,
            job : false,
            birthday : false,
            phone : false,
            phoneFormat : false,
            country : false,
            preference : false,
            description : false,
        };
        
        if(!this.state.name){
            Valid = false;
            errors['name'] = true;
        }else{
            errors['name'] = false;
        }
        
        if(!this.state.lastname){
            Valid = false;
            errors['lastname'] = true;
        }else{
            errors['lastname'] = false;
        }
        // if(!this.state.job){
        //     Valid = false;
        //     errors['job'] = true;
        // }else{
        //     errors['job'] = false;
        // }
        
        // if(!this.state.phone){
        //     Valid = false;
        //     errors['phone'] = true;
        // }else{
        //     if(!Validation.PhoneNumber(this.state.phone)){
        //         Valid = false;
        //         errors['phoneFormat'] = true;
                
        //     }else{
        //         errors['phoneFormat'] = false;
        //     }
        //     errors['phone'] = false;
        // }
        
        // if(
        //     (!this.state.day || Number(this.state.day) === 0) && 
        //     (!this.state.month  || Number(this.state.month) === 0) && 
        //     (!this.state.year || this.state.year === "0000")){
        //     Valid = false;
        //     errors['birthday'] = true;
        // }else{
        //     errors['birthday'] = false;
        // }
        if(!this.state.country){
            Valid = false;
            errors['country'] = true;
        }else{
            errors['country'] = false;
        }
        if(!this.state.preference){
            Valid = false;
            errors['preference'] = true;
        }else{
            errors['preference'] = false;
        }
        // if(!this.state.description){
        //     Valid = false;
        //     errors['description'] = true;
        // }else{
        //     errors['description'] = false;
        // }
        
        this.setState({
            errors : errors,
            Error : ""
        })
        if(Valid){
            const data = {
                user : {
                    email : jwt_decode(localStorage.jwtToken).username
                },
                profile : {
                    email : this.state.email,
                    nom: this.state.lastname,
                    prenom: this.state.name,
                    day: this.state.day,
                    month: this.state.month,
                    year: this.state.year,
                    fonction: this.state.job,
                    telephone: this.state.phone || '',
                    pays: this.state.country || '', 
                    langue: this.state.preference || '',
                    description: this.state.description || '',
                    civilite: this.state.civility || '', 
                    casecoche: 1, 
                }
            }
            axios
            .post(Global.API_URL+"/api/profil", data)
            .then((res) => {
                
                if(res.data.status === true){
                    this.props.history.push('/terminer-inscription/photo') 
                }else{
                    throw new Error('warning : La mise à jour n\'a pas été effectué');
                }
            })
            .catch((err) => {
                this.setState({
                    Error : (err.message ? err.message : err)
                })
            });
        }
    }  
    
    componentDidMount(){
        if(localStorage.jwtToken){

            const rand = Math.random(0,1000000000000000);

            axios
            .get(Global.API_URL+"/api/checkprofil/"+SHA1(rand))
            .then((res) => {
                
                if(
                    res.data.civilite || 
                    res.data.nom || 
                    res.data.prenom || 
                    res.data.day || 
                    res.data.month || 
                    res.data.year || 
                    res.data.fonction || 
                    res.data.telephone || 
                    res.data.pays || 
                    res.data.langue || 
                    res.data.description
                ){
                    this.setState({
                        civility : res.data.civilite || this.state.civility,
                        civilitySelect : res.data.civilite ? {value : res.data.civilite, label : res.data.civilite} : this.state.civilitySelect,
                        lastname : res.data.nom,
                        name : res.data.prenom,
                        day : res.data.day ? res.data.day : 0,
                        month : res.data.month ? res.data.month : 0,
                        year : res.data.year ? res.data.year : "0000",
                        job : res.data.fonction,
                        phone : res.data.telephone,
                        country : res.data.pays || this.state.country,
                        preference : res.data.langue || this.state.preference,
                        description : res.data.description,
                    });  
                    if(res.data.day || res.data.month || res.data.year){
                        this.setState({
                            dateIsCorrect : "dateiscorrect"
                        })
                    }          
                }

            })
            .catch((err) => {
                
            });
        }
    }
    render() {
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="_cnu-tabs">
                                            <div className="_cnu-logo">
                                                <img src={configs.GetImage('white-logo.png')} alt="" />
                                            </div>
                                            <h3>Compléter mon profil</h3>
                                            <div className="bg-white-transparent p-10 brs-10">
                                                <div className="tab _mp-form">
                                                    <form id="_Form" action="/terminer-inscription/photo" className="form" onSubmit={this.handleSubmit}>
                                                    
                                                        <div style={{display: 'none'}} className={"form-group " + (this.state.errors.civility ? " hasError " : "")}>
                                                                <Select
                                                                name="Civility"
                                                                className={"_form-control "}
                                                                id="_Civility"
                                                                placeholder="Civilité"
                                                                value={this.state.civilitySelect}
                                                                onChange={this.handleSelectChange} 
                                                                options={[
                                                                    { 'value': 'M.', 'label': 'M.' },
                                                                    { 'value': 'Mme', 'label': 'Mme' },
                                                                    { 'value': 'Melle', 'label': 'Melle' },
                                                                ]}
                                                                />
                                                                <div className="errorMsg">Civilité est Obligatoire</div>
                                                        </div>
                                                        <input
                                                            type="hidden"
                                                            name="Civility"
                                                            id="_Civility"
                                                            value="M."
                                                            /> 

                                                        <InputField 
                                                            type="text" 
                                                            id="_name" 
                                                            name="name" 
                                                            label="Prénom" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError ": this.state.errors.name,
                                                            })} 
                                                            value={this.state.name} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "Le prénom est obligatoire": this.state.errors.name,
                                                            })} />

                                                        <InputField 
                                                            type="text" 
                                                            id="_lastname" 
                                                            name="lastname" 
                                                            label="Nom" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError ": this.state.errors.lastname,
                                                            })} 
                                                            value={this.state.lastname} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "Le nom est obligatoire": this.state.errors.lastname,
                                                            })} />

                                                        <InputField 
                                                            type="text" 
                                                            id="_job" 
                                                            name="job" 
                                                            label="Fonction" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError": this.state.errors.job,
                                                            })} 
                                                            value={this.state.job} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "Le fonction est obligatoire": this.state.errors.job,
                                                            })} />
                                                       
                                                        <InputField 
                                                            type="text" 
                                                            id="_phone" 
                                                            name="phone" 
                                                            label="Téléphone" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError": this.state.errors.phone,
                                                                "  hasError": this.state.errors.phoneFormat,
                                                            })} 
                                                            value={this.state.phone} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "Le téléphone est obligatoire": this.state.errors.phone,
                                                                "Le format de téléphone est incorrect": this.state.errors.phoneFormat,
                                                            })} />

                                                        <InputField 
                                                            type="date" 
                                                            id="_birthday" 
                                                            name="birthday" 
                                                            label="Date de naissance (jj/mm/aaaa)" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            errorClass={classnames({
                                                                " hasError ": this.state.errors.birthday,
                                                            })}
                                                            classContainer={this.state.dateIsCorrect} 
                                                            value={Date.parse(this.state.month + "/" +this.state.day + "/" + this.state.year)} 
                                                            oninput={this.handleDateChange} 
                                                            errorMsg={classnames({
                                                                "La date de naissance est obligatoire": this.state.errors.birthday,
                                                            })} />

                                                        <InputField 
                                                            type="text" 
                                                            id="_country" 
                                                            name="country" 
                                                            label="Pays" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError": this.state.errors.country,
                                                            })} 
                                                            value={this.state.country} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "Le pays est obligatoire": this.state.errors.country,
                                                            })} />
                                                        <input 
                                                            type="hidden" 
                                                            id="_country" 
                                                            name="country" 
                                                            label="Pays" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError": this.state.errors.country,
                                                            })} 
                                                            value={this.state.country} />

                                                        <div>
                                                            <InputField 
                                                                type="select" 
                                                                items={configs.getLanguages()}
                                                                id="_preference" 
                                                                name="preference" 
                                                                label="Préférence de langue" 
                                                                fixedLabel="false" 
                                                                classInput="" 
                                                                classContainer={classnames({
                                                                    " hasError": this.state.errors.preference,
                                                                    " select-preference-language" : true
                                                                })}
                                                                default={{
                                                                    value: this.state.preference,
                                                                    label: this.state.preference
                                                                }}
                                                                oninput={(e)=>{this.handleInputChange({target:{name:'preference', value: e.value}})}} 
                                                                errorMsg={classnames({
                                                                    "Le Préférence de langue est obligatoire": this.state.errors.preference,
                                                                })} />
                                                        </div> 

                                                        <input 
                                                            type="hidden" 
                                                            id="_preference" 
                                                            name="preference" 
                                                            label="Préférence de langue" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError": this.state.errors.country,
                                                            })} 
                                                            value={this.state.preference} />


                                                        {/* <InputField 
                                                            type="textarea" 
                                                            id="_description" 
                                                            name="description" 
                                                            label="Description" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError": this.state.errors.description,
                                                            })} 
                                                            value={this.state.description} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "La description est obligatoire": this.state.errors.description,
                                                            })} /> */}
                                                        <input 
                                                            type="hidden" 
                                                            id="_description" 
                                                            name="description" 
                                                            label="Description" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer={classnames({
                                                                " hasError": this.state.errors.description,
                                                            })} 
                                                            value={this.state.description} 
                                                            errorMsg={classnames({
                                                                "La description est obligatoire": this.state.errors.description,
                                                            })} />

                                                    </form>

                                                    {this.state.Error &&
                                                    <div className="_alert _alert-danger">
{this.state.Error}
                                                    </div>
                                                    }
                                                </div>
                                                <div className="_cnu-footer-tabs footer-fixed m-t-10">
                                                    <div className="d-flex jc-space-b">
                                                        <Link to="/terminer-inscription/annuler" className="button lg icon wt">
                                                            <i className="fa fa-chevron-left"></i> ANNULER
                                                        </Link>
                                                        <button form="_Form" type="submit" className="button lg icon"> 
                                                            SUIVANT <i className="fa fa-chevron-right"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </section>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


FinishProfil.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(FinishProfil);
