import React, { useEffect, useState } from 'react'; 
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./inc/SetAuthToken";
import { setCurrentUser, LoginExpired } from "./actions/authentication";
import Layout from './components/Layout';
import Connexion from './components/connexion/Connexion'
import ForgetPassword from './components/connexion/ForgetPassword'
import FirstLogin from './components/connexion/FirstLogin'
import ResetForgetPassword from './components/connexion/ResetForgetPassword' 
import ScrollToTop from './components/ScrollToTop';
import Global from './inc/Global';
import './assets/css/custom-icons.css' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { onMessageListener } from './inc/FireBase';     
import ChatList from './components/header/Chat'
import PdfViewerPage from './components/documents/PdfViewerPage';

document.title = Global.APP_NAME || 'Bienvenu sur ';
document.querySelector('meta[name="description"]').setAttribute("content", Global.APP_NAME);

if (localStorage.jwtToken && localStorage.jwtUd) {
    
    setAuthToken(localStorage.jwtToken);
    const decoded = jwt_decode(localStorage.jwtToken);
    // axios
    // .get(Global.API_URL + "/api/checkprofil/" + SHA1(new Date()))
    // .then((response) => {
    //     // console.log(decoded);
    //     decoded['profil'] = response.data.image;
    //     decoded['name'] = response.data.prenom;
    //     decoded['lastname'] = response.data.nom;
    //     decoded['user_id'] = response.data.user_id;

        
    // }).catch((err) => {
    //     // console.log(err.message);
    // });
    
    
    store.dispatch(setCurrentUser(JSON.parse(localStorage.jwtUd)));

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(LoginExpired());
        window.location.href = "/login";
    } 
}

 function App(props) { 

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/assets/js/script.js';
        script.async = true;
        document.body.appendChild(script);

        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = '/assets/css/style.css';
        document.head.appendChild(style);  
        // console.log('onMessageListener()',onMessageListener());
        // const onMessageListenerVar = onMessageListener()
        if( onMessageListener() ){
            onMessageListener().onMessage((payload) => {   
                let data = JSON.parse(payload.data.data)  
                const Msg = ({ closeToast }) => (
                    <div>
                        <strong>{payload.notification.title}</strong>
                        <p>{payload.notification.body}</p>
                    </div>
                )
            
                toast(<Msg/>, {
                    position: "bottom-right",
                    autoClose: 20000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClick:()=>{
                        if( data.action == 'single-post' ){
                            window.location.href = `/communaute/${data.payload.idGroup}/post/${data.payload.idPost}`
                        }
                        if( data.action == 'new-message' ){
                            ChatList.startChatByConversationId(data.payload.id)
                        }
                    }
                }); 
            });
        } 
    }); 

    

    return (
        <>   

            <div className="push-notif-toaster">
                <ToastContainer />
            </div>

            <Provider store={store}>
                <Router history={new createBrowserHistory() }>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/login" component={Connexion} />
                        <Route path="/terminer-inscription" component={FirstLogin} />
                        <Route exact path="/forget" component={ForgetPassword} />
                        <Route exact path="/pass" component={ResetForgetPassword} /> 
                        <Route exact path="/PdfView" component={PdfViewerPage} /> 
                        <Route path="*" component={Layout} />
                    </Switch>
                </Router>
            </Provider>
        </>
    );
}

export default App;
