import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/img/white-logo.png'
import Global from '../../inc/Global';

class AcceptTheConditions extends Component {

    render() {
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="_cnu-tabs">
                                            <div className="_cnu-logo">
                                                <img src={Global.APP_LOGO} alt="" />
                                            </div>
                                            <h3>Conditions générales d'utilisation</h3>
                                            <div className="bg-white p-10 brs-10">
                                                <div className="tab entry-cdt-general">
                                                    <strong>Lorem ipsum dolor sit amet</strong><br />
                                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente ullam explicabo numquam est quia, maiores architecto vero eos, sint beatae fugit placeat odio minus fugiat? Sed tempore autem deserunt, nobis illo, totam voluptatem unde quia quo labore consequatur explicabo quae perferendis error. <br /><br />
                                                    <strong> veritatis ipsum. Dolores quos quaerat esse</strong><br /> 
                                                    ratione nam necessitatibus. Fugit velit culpa laborum veritatis sequi reiciendis voluptatibus asperiores quisquam consectetur hic deserunt eos suscipit cum et officiis, sed expedita dolorem quam eaque explicabo! Asperiores eos voluptatem excepturi quo labore similique accusantium, error beatae optio tempore dignissimos deleniti! Tenetur error possimus, consequuntur velit obcaecati commodi dicta consequatur, minima praesentium ipsam dignissimos enim, reiciendis optio nulla ut ab. Repellat, quidem! Sint perspiciatis distinctio provident doloribus officia accusamus sit, soluta et. Architecto illum fugiat veniam cum voluptatibus beatae alias natus quibusdam dolorem iure laudantium sed similique ad quaerat ipsa iusto ex hic quae. <br /><br />
                                                    <strong>aperiam magni perspiciatis harum impedit, saepe iste! Quam ea velit non harum. Aliquid, ipsa ratione</strong><br />
                                                    rem blanditiis culpa voluptatibus quis quaerat magnam ab aliquam reprehenderit maxime et natus repudiandae praesentium id velit consectetur totam molestiae doloremque ea quidem nesciunt, odio reiciendis! Enim porro asperiores suscipit tempore optio cupiditate esse, a nostrum, voluptatem, molestias unde quo quis assumenda? Maxime. <br /><br />
                                                    quo labore similique accusantium, error beatae optio tempore dignissimos deleniti! Tenetur error possimus, consequuntur velit obcaecati commodi dicta consequatur, minima praesentium ipsam dignissimos enim, reiciendis optio nulla ut ab. Repellat, quidem! Sint perspiciatis distinctio provident doloribus officia accusamus sit, soluta et. Architecto illum fugiat veniam cum voluptatibus beatae alias natus quibusdam dolorem iure laudantium sed similique ad quaerat ipsa iusto ex hic quae. <br />
                                                    <br /><br />quo labore similique accusantium, error beatae optio tempore dignissimos deleniti! Tenetur error possimus, consequuntur velit obcaecati commodi dicta consequatur, minima praesentium ipsam dignissimos enim, reiciendis optio nulla ut ab. Repellat, quidem! Sint perspiciatis distinctio provident doloribus officia accusamus sit, soluta et. Architecto illum fugiat veniam cum voluptatibus beatae alias natus quibusdam dolorem iure laudantium sed similique ad quaerat ipsa iusto ex hic quae.
                                                </div>
                                            </div>
                                            <div className="_cnu-footer-tabs m-t-10">
                                                <div className="d-flex jc-space-b">
                                                    <Link to="/terminer-inscription/annuler" className="button lg wt">Annuler</Link>
                                                    <Link to="/terminer-inscription/profil" className="button lg">Accepter</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AcceptTheConditions;