import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { IsLoggedInForFirstTime } from '../actions/authentication';
import jwt_decode from "jwt-decode";
import LeftSidebar from './aside/LeftSidebar';
import RightSidebar from './aside/RightSidebar';
import CommunityRightSidebar from './aside/CommunityRightSidebar'
import Header from './header/Header';
import Communities from './community/Communities';
import Home from './home/Home';
import NotFound from '../NotFound';
import Admin from './admin/Admin';
import AddCommunity from './community/AddCommunity';
import UpdateCommunity from './community/UpdateCommunity';
import Settings from './community/Settings';
import UsersOfCommunity from './community/UsersOfCommunity';
import Invitations from './community/Invitations';
import InviteMembers from './community/InviteMembers';
import InviteExternMembers from './community/InviteExternMembers';
import UserProfile from './user/UserProfile';
import MyPosts from './user/MyPosts';
import MyCommunities from './user/MyCommunities';
import Community from './community/Community';
import Library from './documents/Library';
import SavedPosts from './user/SavedPosts';
import UsersList from './admin/Users/UsersList';
import EditProfile from './user/EditProfile';
import Administration from './admin/administration'
import SearchResulats from './search-results/SearchResulats'
import '../assets/css/layouts.css'
import Footer from './footer/Footer';
import NotificationParam from './user/NotificationParam';

import Axios from 'axios'
import Global from '../inc/Global'
import CallenderCom from './community/CallenderCom';

class Layout extends Component {
    constructor(props){
        super(props)
        // window.addEventListener('beforeunload', function (e) {
        //     e.preventDefault();
        //     Axios.get(Global.API_URL+"/admin/platformget")
        //     .then((res) => {
        //         console.log('aaa',res);
                
        //     })
        //     var confirmationMessage = "o/";
        //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        //     return confirmationMessage; //Webkit, Safari, Chrome
        // });
    }
    componentDidMount() {
        if ( !this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
        
        document.addEventListener('mouseup', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }
    handleClickOutside(ev){
        ev.preventDefault();
        
        var CheckBoxes = document.querySelectorAll('.dropdowns .profil input[type="checkbox"], .dropdowns .notifs input[type="checkbox"], .rm-list input[type="checkbox"]');

        CheckBoxes.forEach(function(el, index){
            el.addEventListener('change', function(){
                CheckBoxes.forEach(function(currentItem, currentIndex){
                    if(el !== currentItem){
                        currentItem.checked = false;
                    }
                })

            });
        });

        var CheckboxesLabel = document.querySelectorAll('.rm-list .toggle, .dropdowns .profil label, .dropdowns .notifs label');
        var AtLeastOnClicked = false;
        CheckboxesLabel.forEach(function(el, index){
            if(el.contains(ev.target)){
                AtLeastOnClicked = true;
            }
        });
        if(!AtLeastOnClicked){
            CheckBoxes.forEach(function(currentItem, currentIndex){
                currentItem.checked = false;
            });
        }
    }
    
    render() {
        if (this.props.auth.isAuthenticated) {
            const decoded = jwt_decode(localStorage.jwtToken);
            this.props.IsLoggedInForFirstTime(decoded.username, this.props);
        }else{
            return "";
        }
        return (
            <div className="App" >
                <Route path="/" component={Header} />
                <div className="section" id="_section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <Switch>
                                    <Route path="/communaute/:id" component={LeftSidebar} />
                                    <Route path="/" component={LeftSidebar} />
                                </Switch>
                                    
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/search/:string" component={SearchResulats} />
                                    <Route exact path="/communautes" component={Communities} />
                                    {/* <Route exact path="/utilisateur/parametres" component={UserMenu} /> */}
                                    <Route exact path="/notification" component={NotificationParam} />
                                    <Route exact path="/profil" component={EditProfile} />
                                    <Route exact path="/utilisateur/profil/:id" component={UserProfile} />
                                    <Route exact path="/mes-publications" component={MyPosts} />
                                    <Route exact path="/mes-communautes" component={MyCommunities} />
                                    <Route exact path="/mes-enregistrements" component={SavedPosts} />
                                    <Route exact path="/utilisateurs" component={UsersList} />
                                    <Route path="/admin" component={Admin} />
                                    {/* Communities */}
                                    <Route exact path="/communaute/:id" component={Community} />
                                    <Route exact path="/communaute/:id/post/:postId" component={Community} />
                                    <Route exact path="/communaute/:id/mediatheque/" component={Community} />
                                    <Route exact path="/communaute/:id/mediatheque/:folderId" component={Community} />
                                    <Route exact path="/communaute/:id/mediatheque/:folderId/:default" component={Community} />

                                    {/* Sondage */}
                                    <Route exact path="/communaute/:id/sondages" component={Community} />

                                    {/* Calendrier */}
                                    <Route exact path="/communaute/:id/calendrier" component={CallenderCom} />

                                    <Route path="/communautes/ajouter" component={Communities} />
                                    <Route path="/communaute/:id/modifier" component={UpdateCommunity} />
                                    <Route path="/communaute/:id/parametres" component={Settings} />
                                    <Route path="/communaute/:id/users" component={UsersOfCommunity} />
                                    <Route path="/communaute/:id/invitations" component={Invitations} />
                                    <Route path="/communaute/:id/inviter-membre" component={InviteMembers} />
                                    <Route path="/communaute/:id/inviter-extern-membre" component={InviteExternMembers} />
                                    {/* Documents */}
                                    <Route exact path="/mediatheque" component={Library} />
                                    <Route exact path="/mediatheque/:id" component={Library} />

                                    <Route path="/administration" component={Administration} />

                                    <Route path="*" component={NotFound} />
                                </Switch>
                            
                                
                                <Switch>
                                    <Route path="/communaute/:id" component={CommunityRightSidebar} />
                                    <Route path="/" component={RightSidebar} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
                <Route path="/" component={Footer} />
            </div>
        )
    }
}


Layout.propTypes = {
    IsLoggedInForFirstTime: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { IsLoggedInForFirstTime })(Layout);